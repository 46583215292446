import React from 'react';
import { Alert } from 'antd';
import { useRecentUploadingCount } from '../../api';

const RecentUploadingCount: React.FC<{
    studyId: string,
    organizationId?: string,
    clinicToken?: string,
}> = ({ studyId, organizationId, clinicToken }) => {
    const { data, isLoading } = useRecentUploadingCount(studyId, organizationId, clinicToken);

    if (!isLoading && data && data.recent_uploading_count > 0) {
        return (
            <Alert
                type="warning"
                message={`Do not turn off the computer. There are ${data.recent_uploading_count} datasets still uploading.`}
                showIcon 
            />
        );
    }

    return null;
};

export default RecentUploadingCount;