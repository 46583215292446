import React, { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react'
import { useParams, useHistory, generatePath, Switch, Route, useRouteMatch, Link } from 'react-router-dom'
import { Input, Checkbox, Space, Table, Typography, Tag, Select, Tooltip, Popover, Card, DatePicker } from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import { useFilteredStudySessions, StudySession, StudySessionsFilter, useParticipants as useParticipants, useExperiments, useStudyMembers, StudySessions, useDataTransforms, useAllDataStreams } from '../../api'
import styles from './styles.module.css'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import StopClickPropagation from '../../components/StopClickPropagation'
import { StopOutlined, WarningOutlined, CheckOutlined } from '@ant-design/icons'
import FileDownload from '../FileDownload'
import Routes from '../../routes'
import SessionPage from '../../pages/Session';
import createPersistedState from 'use-persisted-state'
import { Loader, LoadingIndicator } from '../Loader'
import { color } from '../../theme'
import dayjs from 'dayjs';
import RecentUploadingCount from '../RecentUploadingCount'

const { Option } = Select

const { Text } = Typography

/**
 * the type of the value for a filter. many filter types
 * could have the same filter value (Date & Numeric would both
 * use `number` FilterValue)
 */
type FilterValue = string | number | boolean

/**
 * the base config required to render a filter
 */
type FilterConfig<T extends FilterValue> = {
  /**
   * A 1:1 to server accepted `PUT` body fields
   *
   * @required
   */
  key: string
  onChange: (newValue?: T) => void
  title?: string
  value?: T
}

type StringFilterProps<T extends FilterValue> = {
  config: FilterConfig<T>
}

const StringFilter: React.FC<StringFilterProps<string>> = ({ config: { onChange, key, title, value } }) => {
  return (
    <Space direction="vertical" className={styles.Filter}>
      <Text className={styles.FilteredSessionsFilterLabel}>{title || key}</Text>
      <Input
        {...value && { value }}
        onChange={({ target }) => {
          const newValue = target.value

          onChange(newValue)
        }}
      />
    </Space>
  )
}

type NumberFilterProps<T extends FilterValue> = {
  config: FilterConfig<T>
}

const NumberFilter: React.FC<NumberFilterProps<number>> = ({ config: { onChange, key, title, value } }) => {
  return (
    <Space direction="vertical" className={styles.Filter}>
      <Text className={styles.FilteredSessionsFilterLabel}>{title || key}</Text>
      <Input
        {...typeof value === 'number' && { value }}
        type="number"
        onChange={({ target }) => {
          const newValue = target.value
          const intNewValue = parseInt(newValue)

          // handle negative & empty input (NaN)
          onChange(intNewValue >= 0 ? intNewValue : undefined)
        }}
      />
    </Space>
  )
}

type BooleanFilterProps<T extends FilterValue> = {
  config: FilterConfig<T>
}

const BooleanFilter: React.FC<BooleanFilterProps<boolean>> = ({ config: { onChange, key, title, value } }) => {
  return (
    <Space direction="vertical" className={styles.Filter}>
      <Text className={styles.FilteredSessionsFilterLabel}>{title || key}</Text>
      <Checkbox
        {...typeof value === 'boolean' && { defaultChecked: value }}
        onChange={({ target }) => {
          const newValue = target.checked

          onChange(newValue)
        }}
      />
    </Space>
  )
}

type SelectFilterConfig<T extends FilterValue> = FilterConfig<T> & {
  options?: { value: T, title?: string }[]
  defaultValue?: T
  disabled?: boolean
  loading?: boolean
  showSearch?: boolean
}

type SelectFilterProps<T extends FilterValue> = {
  config: SelectFilterConfig<T>
}

const SelectFilter: React.FC<SelectFilterProps<string>> = ({ config: { onChange, key, options, defaultValue, disabled, loading, value, title, showSearch } }) => {
  return (
    <Space direction="vertical" className={styles.Filter}>
      <Text className={styles.FilteredSessionsFilterLabel}>{title || key}</Text>

      {loading ? (
        <Loader size="small" />
      ) : (
        <Select
          className={styles.SelectFilter}
          {...defaultValue && { defaultValue }}
          {...value && { value }}
          {...disabled && { disabled }}
          {...showSearch && {
            showSearch,
            optionFilterProp: "children",
            filterOption: true,
          }}
          onChange={newValue => {
            onChange(newValue)
          }}
          allowClear={true}
        >
          {options?.map?.(({ value, title }) => <Option key={value} value={value}>{title || value}</Option>)}
        </Select>
      )}
    </Space>
  )
}

type DateTimeFilterConfig<T extends FilterValue> = FilterConfig<T> & {
  disabled?: boolean
  loading?: boolean
}

type DateTimeFilterProps<T extends FilterValue> = {
  config: DateTimeFilterConfig<T>,
}

const DateTimeFilter: React.FC<DateTimeFilterProps<number>> = ({ config: { onChange, key, disabled, loading, title, value } }) => {
  return (
    <Space direction="vertical" className={styles.Filter}>
      <Text className={styles.FilteredSessionsFilterLabel}>{title || key}</Text>

      {loading ? (
        <Loader size="small" />
      ) : (
        <DatePicker
          showTime
          {...disabled && { disabled }}
          value={value ? dayjs.unix(value) : undefined}
          onChange={(newValue) => {
            if (newValue) {
              onChange(newValue.unix())
            } else {
              onChange()
            }
          }}
        />
      )}
    </Space>
  )
}

const EmptySessionTableColumn: React.FC = () => {
  return (
    <Text type="secondary"><em>-</em></Text>
  )
}

const Sessions: React.FC<{ filter: StudySessionsFilter, setFilter: Dispatch<SetStateAction<StudySessionsFilter>>, sessions?: StudySessions, sessionsLoading: boolean}> = ({ filter, setFilter, sessions, sessionsLoading }) => {
  const { url } = useRouteMatch()
  const columns: ColumnsType<StudySession> = useMemo(() => ([
    ...filter.allow_invalid ? [
      {
        title: 'Invalid',
        dataIndex: ['meta', 'invalid'],
        key: 'invalid',
        render: function Invalid(text, { meta }) {
          const invalid = meta?.invalid

          if (invalid) {
            return (
              <Tooltip title={invalid}>
                <StopOutlined style={{ color: color.red }} />
              </Tooltip>
            )
          } else {
            return <EmptySessionTableColumn />
          }
        }
      },
    ] as ColumnsType<StudySession> : [] as ColumnsType<StudySession>,
    {
      title: 'Start Time',
      dataIndex: 'created_date',
      key: 'created_date',
      render: function CreatedDate(text, { created_date, duration = 0 }) {
        return (
          <Space direction="vertical" size={0}>
            <Tooltip title={moment.unix(created_date).format()}>
              <Text style={{ whiteSpace: 'nowrap' }}>{moment.unix(created_date).format('MMM DD LT')}</Text>
            </Tooltip>

            <Tooltip title={moment.utc(duration * 1000).format("HH:mm:ss")}>
              <Text style={{ fontSize: 12 }}>{moment.duration({ seconds: duration }).humanize()}</Text>
            </Tooltip>
          </Space>
        )
      }
    },
    {
      title: 'Participant',
      dataIndex: ['participant', 'participant_id'],
      key: 'study',
      render: function ParticipantId(participantId: string, { _permissions }: StudySession) {
        return (
          <Space>
            <Text style={{ whiteSpace: 'nowrap' }}>{participantId}</Text>
            {!_permissions.can_download && (
              <Tooltip title={`${participantId}'s datasets will not be included in downloads`}>
                <WarningOutlined style={{ color: color.yellow, fontSize: 16 }} />
              </Tooltip>
            )}
          </Space>
        )
      },
      sorter: (a, b) => a.participant.participant_id.localeCompare(b.participant.participant_id)
    },
    {
      title: 'Researcher',
      dataIndex: 'created_by',
      key: 'researcher',
      render: function Researcher(text, { created_by_email }) {
        return (
          <Tooltip title={created_by_email}>
            <Text>{created_by_email.split('@')[0]}</Text>
          </Tooltip>
        )
      },
      sorter: (a, b) => a.created_by ? b.created_by ? a.created_by.localeCompare(b.created_by) : -1 : 1
    },
    {
      title: 'Session',
      dataIndex: 'id',
      key: 'session',
      render: function Session(id: string, { meta }) {
        const number = meta?.number ?? ""
        const name = meta?.name
        const description = meta?.description

        return (
          <Space direction="vertical" size={0}>
            <Space>
              <StopClickPropagation>
                <Tooltip title={<Text style={{ fontSize: 12 }}>{id}</Text>} placement="right">
                  <Text copyable={{ text: id, tooltips: ['Copy Dataset Id', 'Copied'] }} type={"secondary"} />
                </Tooltip>
              </StopClickPropagation>

              {!isNaN(parseInt(number)) && <Text><strong>{number}. </strong></Text>}
              {name && <Text>{name}</Text>}
            </Space>

            {description && <Text style={{ fontSize: 12 }}>{description}</Text>}
          </Space>
        )
      }
    },
    {
      title: 'Task',
      dataIndex: ['meta', 'experiment'],
      key: 'experiment',
      render: function Experiment(text, { meta }) {
        const experiment = meta?.experiment

        if (experiment) {
          return <Tag>{experiment}</Tag>
        } else {
          return <EmptySessionTableColumn />
        }
      }
    },
    {
      title: 'Data Streams',
      dataIndex: 'data_streams',
      key: 'data_streams',
      render: function DataStreams(text, { data_streams }) {
        return data_streams.map((dataStreamId) => (
          <Tag key={dataStreamId}>
            <Text style={{ maxWidth: 80 }} ellipsis={{ tooltip: sessions?.data_streams?.[dataStreamId]?.name }}>{sessions?.data_streams?.[dataStreamId]?.name}</Text>
          </Tag>
        ))
      }
    },
  ]), [sessions]);

  const history = useHistory()

  return (
    <Table
      className={`ant-table-hover ${styles.FilteredSessionsSessions}`}
      onRow={({ id, participant }) => ({
        onClick: () => {
          history.push(url + `/participant/${participant.id}/session/${id}`)
        }
      })}
      pagination={{ 
        simple: true, 
        pageSize: filter.limit, 
        current: (filter.offset/filter.limit) + 1, 
        total: sessions?.total_session_count,
        onChange: (newPage) => {
          setFilter(prev => ({ 
            ...prev, 
            offset: (newPage -1) * prev.limit 
          }))
        } 
      }}
      {...sessionsLoading && {
        loading: {
          indicator: <LoadingIndicator />
        }
      }}
      dataSource={sessions?.sessions}
      columns={columns}
      bordered={false}
      size='small'
      rowKey="id"
    />
  )
}

type FilteredSessionsProps = {
  /**
   * A record of values to "lock" a filter field. for example, if { participant } is provided the participant
   * select will be disabled and contain the provided `FilterValue` for the key `participant`
   */
  overrideFilterValues?: StudySessionsFilter
}

const FilteredSessions: React.FC<FilteredSessionsProps> = ({
  overrideFilterValues = {
    limit: 14,
    offset: 0
  },
}) => {
  const { path } = useRouteMatch()
  const matchResearcherFilter = useRouteMatch<{ userId?: string }>('/organizations/:organizationId/studies/:studyId/datasets/filter/created_by/:userId')
  if (matchResearcherFilter?.params.userId) {
    overrideFilterValues["created_by"] = matchResearcherFilter?.params.userId
  }
  const matchParticipantFilter = useRouteMatch<{ virtualUserId?: string }>('/organizations/:organizationId/studies/:studyId/datasets/filter/participant/:virtualUserId')
  if (matchParticipantFilter?.params.virtualUserId) {
    overrideFilterValues["participant"] = matchParticipantFilter?.params.virtualUserId
  }

  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId?: string }>()
  const usePersistedFilterState = createPersistedState<StudySessionsFilter>(`portal-session-filter-${studyId}`)
  const [filter, setFilter] = usePersistedFilterState({
    ...overrideFilterValues
  })
  const { isLoading: experimentsLoading, data: experiments } = useExperiments(organizationId, studyId)
  const { isLoading: participantsLoading, data: participants } = useParticipants(organizationId, studyId)
  const { isLoading: membersLoading, data: members } = useStudyMembers(organizationId, studyId)
  const { isLoading: dataTransformsLoading, data: dataTransforms } = useDataTransforms()
  const { isLoading: dataStreamsLoading, data: dataStreams } = useAllDataStreams(organizationId, studyId)
  useEffect(() => {
    setFilter(overrideFilterValues)
  }, [JSON.stringify(overrideFilterValues)])

  const studyDownloadUrl = `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOWNLOAD_ORGANIZATION_STUDY_DATA, {
    organizationId,
    studyId
  })}`

  // TODO: remove me from firing on the session detail page. need to only run this on exact route
  const { data: sessions, isLoading: sessionsLoading } = useFilteredStudySessions(organizationId, studyId, filter)

  const match = useRouteMatch<{ sessionId?: string }>('/organizations/:organizationId/studies/:studyId/datasets/participant/:virtualUserId/session/:sessionId')
  const participantSessionId = match?.params.sessionId

  const allDatasetsRoute = {
    title: <Link to={generatePath('/organizations/:organizationId/studies/:studyId/datasets', { organizationId, studyId })}>All Datasets</Link>,
  }
  const [routes, setRoutes] = useState([allDatasetsRoute]);

  useEffect(() => {
    setRoutes([
      allDatasetsRoute,
      ...participantSessionId ? [{
        title: <>{participantSessionId}</>,
      }] : []
    ])
  }, [participantSessionId])

  return (
    <PageHeader
      breadcrumb={{ items: routes }}
      {...!participantSessionId && {
        extra: [
          <Popover key="download-datasets" trigger="hover" placement="bottomLeft" title="Download Datasets" content={(
            <Space direction="vertical">
              <Space>
                <CheckOutlined style={{ color: color.green, fontSize: 16 }} />
                <span>Includes any available SNIRF files and reports from selected datasets (run `download.py` in zip file)</span>
              </Space>
              <Space>
                <CheckOutlined style={{ color: color.green, fontSize: 16 }} />
                <span>Includes surveys from selected datasets</span>
              </Space>
              <Space>
                <CheckOutlined style={{ color: color.green, fontSize: 16 }} />
                <span>Includes metadata from selected datasets</span>
              </Space>
              {sessions?.sessions.some(({ _permissions }) => !_permissions.can_download) && (
                <Space direction="vertical">
                  <Space>
                    <WarningOutlined style={{ color: color.yellow, fontSize: 16 }} />
                    <span>This download will only include processed datasets that are ready for analysis</span>
                  </Space>
                </Space>
              )}
            </Space>
          )}>
            <Space>{/* required for Popover to work around SVG elements */}
              <Text strong>Download Datasets</Text>
              <FileDownload url={studyDownloadUrl} filter={filter} />
            </Space>
          </Popover>
        ]
      }}
    >
      <Switch>
        <Route path={`${path}/participant/:virtualUserId/session/:sessionId`}>
          <SessionPage />
        </Route>
        <Route path={path}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <RecentUploadingCount organizationId={organizationId} studyId={studyId} />
            <Space className={styles.FilteredSessionsContent} size="large" align="start">
              <Card>
                <Space direction="vertical" className={styles.FilteredSessionsFilters}>
                  <StringFilter config={{
                    title: 'ID',
                    key: 'id',
                    ...filter.id && { value: filter.id },
                    onChange: id => {
                      const { id: _, ...filterWithoutId } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutId,
                        ...id && { id },
                        offset: 0
                      })
                    }
                  }} />

                  <SelectFilter config={{
                    title: 'Participant',
                    key: 'participant',
                    loading: participantsLoading,
                    options: participants?.participants?.map?.(({ id, participant_id }) => ({ value: id, title: participant_id })),
                    ...filter.participant && { value: filter.participant },
                    onChange: participant => {
                      const { participant: _, ...filterWithoutParticipant } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutParticipant,
                        ...participant && { participant },
                        offset: 0
                      })
                    },
                    showSearch: true,
                  }} />

                  <SelectFilter config={{
                    title: 'Task',
                    key: 'experiment',
                    loading: experimentsLoading,
                    options: experiments?.map?.(({ name, display_name }) => ({ value: name, title: display_name || name })),
                    ...filter.experiment && { value: filter.experiment },
                    onChange: experiment => {
                      const { experiment: _, ...filterWithoutExperiment } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutExperiment,
                        ...experiment && { experiment },
                        offset: 0
                      })
                    }
                  }} />
                  
                  <StringFilter config={{
                    title: 'Session Name',
                    key: 'name',
                    ...filter.name && { value: filter.name },
                    onChange: name => {
                      const { name: _, ...filterWithoutName } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutName,
                        ...name && { name },
                        offset: 0
                      })
                    }
                  }} />

                  <StringFilter config={{
                    title: 'Session Number',
                    key: 'number',
                    ...filter.number && { value: filter.number },
                    onChange: number => {
                      const { number: _, ...filterWithoutNumber } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutNumber,
                        ...number && { number },
                        offset: 0
                      })
                    }
                  }} />

                  <StringFilter config={{
                    title: 'Description',
                    key: 'description',
                    ...filter.description && { value: filter.description },
                    onChange: description => {
                      const { description: _, ...filterWithoutDescription } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutDescription,
                        ...description && { description },
                        offset: 0
                      })
                    }
                  }} />

                  <SelectFilter config={{
                    title: 'Researcher',
                    key: 'created_by',
                    loading: membersLoading,
                    options: members?.map?.(({ user_id, email }) => ({ value: user_id, title: email })),
                    ...filter.created_by && { value: filter.created_by },
                    onChange: created_by => {
                      const { created_by: _, ...filterWithoutCreatedBy } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutCreatedBy,
                        ...created_by && { created_by },
                        offset: 0
                      })
                    }
                  }} />

                  <DateTimeFilter config={{
                    title: 'Find After',
                    key: 'start_time',
                    ...filter.start_time && { value: filter.start_time },
                    onChange: start_time => {
                      const { start_time: _, ...filterWithoutStartTime } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutStartTime,
                        ...start_time && { start_time },
                        offset: 0
                      })
                    }
                  }} />

                  <DateTimeFilter config={{
                    title: 'Find Before',
                    key: 'end_time',
                    ...filter.end_time && { value: filter.end_time },
                    onChange: end_time => {
                      const { end_time: _, ...filterWithoutEndTime } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutEndTime,
                        ...end_time && { end_time },
                        offset: 0
                      })
                    }
                  }} />

                  <NumberFilter config={{
                    title: 'Min Duration (seconds)',
                    key: 'min_duration',
                    ...filter.min_duration && { value: filter.min_duration },
                    onChange: min_duration => {
                      const { min_duration: _, ...filterWithoutMinDuration } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutMinDuration,
                        ...min_duration && { min_duration },
                        offset: 0
                      })
                    }
                  }} />

                  <NumberFilter config={{
                    title: 'Max Duration (seconds)',
                    key: 'max_duration',
                    ...filter.max_duration && { value: filter.max_duration },
                    onChange: max_duration => {
                      const { max_duration: _, ...filterWithoutMaxDuration } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutMaxDuration,
                        ...max_duration && { max_duration },
                        offset: 0
                      })
                    }
                  }} />

                  <SelectFilter config={{
                    title: 'Data Stream Type',
                    key: 'data_transform_id',
                    loading: dataTransformsLoading,
                    options: dataTransforms?.map?.(({ id, company, name }) => ({ value: id, title: `${company} ${name}` })),
                    ...filter.data_transform_id && { value: filter.data_transform_id },
                    onChange: data_transform_id => {
                      const { data_transform_id: _, ...filterWithoutDataTransformId } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutDataTransformId,
                        ...data_transform_id && { data_transform_id },
                        offset: 0
                      })
                    }
                  }} />

                  <SelectFilter config={{
                    title: 'Data Stream',
                    key: 'data_stream_id',
                    loading: dataStreamsLoading,
                    options: dataStreams?.map?.(({ id, name }) => ({ value: id, title: `${name}` })),
                    ...filter.data_stream_id && { value: filter.data_stream_id },
                    onChange: data_stream_id => {
                      const { data_stream_id: _, ...filterWithoutDataStreamId } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                      setFilter({
                        ...filterWithoutDataStreamId,
                        ...data_stream_id && { data_stream_id },
                        offset: 0
                      })
                    }
                  }} />
                  <Space align='end'>
                    <BooleanFilter config={{
                      title: 'Include Invalid',
                      key: 'allow_invalid',
                      ...filter.allow_invalid && { value: filter.allow_invalid },
                      onChange: allow_invalid => {
                        const { allow_invalid: _, ...filterWithoutAllowInvalid } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                        setFilter({
                          ...filterWithoutAllowInvalid,
                          ...allow_invalid && { allow_invalid },
                          offset: 0
                        })
                      }
                    }} />
                    <BooleanFilter config={{
                      title: 'Include Withdrawn',
                      key: 'include_withdraw',
                      ...filter.include_withdraw && { value: filter.include_withdraw },
                      onChange: include_withdraw => {
                        const { include_withdraw: _, ...filterWithoutIncludeWithdraw } = filter // eslint-disable-line @typescript-eslint/no-unused-vars
                        setFilter({
                          ...filterWithoutIncludeWithdraw,
                          ...include_withdraw && { include_withdraw },
                          offset: 0
                        })
                      }
                    }} />
                  </Space>
                </Space>
              </Card>
              <Sessions filter={filter} setFilter={setFilter} sessions={sessions} sessionsLoading={sessionsLoading} />
            </Space>
          </Space>
        </Route>
      </Switch>
    </PageHeader>
  )
}

export default FilteredSessions
