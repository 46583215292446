import { Auth } from '@aws-amplify/auth';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import * as Sentry from "@sentry/react";
import { MutationResultPair, QueryCache, QueryConfig, QueryResult, setConsole, useMutation, useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';
import { SurveyStepForm } from '../components/Survey/types';
import Routes from '../routes';
import { downloadFileUrl, downloadJson, fetchAndRaise, prepareHeaders, uploadToS3 } from '../utilities';

setConsole({
  log: console.log,
  warn: console.warn,
  error: console.warn,
})

export type MutationHandlers = {
  onSuccess?: () => void,
  onError: (e?: Error) => void,
}

export const queryCache = new QueryCache()

/* Profile */

export type User = {
  username: string
  attributes: Record<string, string>
};

export const user: () => QueryResult<User, Error> = () =>
  useQuery('user', async () => {
    if (process.env.REACT_APP_PORTAL_LOCAL) {
      const [, attrs,] = process.env.REACT_APP_PORTAL_LOCAL.split(".");
      const user = JSON.parse(atob(attrs)) as Record<string, string>;
      return {
        username: user["cognito:username"],
        attributes: user,
      }
    }

    const user = await Auth.currentAuthenticatedUser() as User;
    console.log(`[portal.api.user] Got attributes: ${JSON.stringify(user.attributes)}`);
    return user;
  }, {
    retry: false,
  })

export const useDeleteUser: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { full_delete: boolean }, unknown> = (handlers) =>
  useMutation(async ({ full_delete }) => {
      console.log(
        `[portal.api.user.delete] Deleting my account and ` + (full_delete ?  `all my data...` : `anonymizing my data...`));
      
      const res = await fetchAndRaise(
        'portal.api.user.delete',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${Routes.ME}`, {
          method: 'delete',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ full_delete })
        });
      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('user')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
  );

export const useUpdateUserEmail: (handlers?: MutationHandlers) => MutationResultPair<string, Error, { new_email: string }, unknown> = (handlers) =>
  useMutation(async ({ new_email }) => {
    new_email = new_email.trim()
    console.log(`[portal.api.user.profile.email.change`);
    const res = await fetchAndRaise(
      'portal.api.user.profile.email.change',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PROFILE_UPDATE_EMAIL,
        {
          new_email
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          new_email
        })
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('user')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  })

export const useVerifyUserEmail: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { email_code: string }, unknown> = (handlers) =>
  useMutation(async ({ email_code }) => {
    console.log(`[portal.api.user.profile.email.change.verify`);

    const res = await fetchAndRaise(
      'portal.api.user.profile.email.change.verify',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PROFILE_VERIFY_UPDATED_EMAIL,
        {
          email_code
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          email_code
        })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('user')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  })

/* Api Version */

export const API_VERSION = 4;

export type ApiVersion = {
  version: number;
  code: string;
};

export const apiVersion: () => QueryResult<ApiVersion, Error> = () =>
  useQuery(
    'api.version',
    async () => {
      console.log(`[portal.api.version] Fetching api version ...`);

      const res = await fetchAndRaise(
        'portal.api.version',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${Routes.API_VERSION}`,
        {
          method: 'get',
          headers: await prepareHeaders(),
        },
      );

      return (await res.json()) as ApiVersion;
    },
    {
      retry: false,
    },
  );

/* Notifications */
export enum NotificationType {
  UserAcceptedEvent = "user_accepted_event",
  UserCancelledEvent = "user_cancelled_event"
}

export type Notification = {
  created_at: number,
  message: string,
  id: string,
  notification_type: NotificationType,
  read: boolean,
  source: Record<"org_id" | "study_id" | "study_name" | "virtual_user_id", string>,
}

export const useNotifications: () => QueryResult<{ notifications: Notification[] }, Error> = () =>
  useQuery(['notifications'], async () => {
    console.log(
      `[portal.api.notifications] Getting notifications from the past 30 days...`,
    );

    const res = await fetchAndRaise(
      'portal.api.notifications',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.NOTIFICATIONS
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
      },
    );

    return await res.json() as { notifications: Notification[] };
  }, {
    retry: false,
  });

export const useMarkNotificationsRead: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, unknown, unknown> = (handlers) =>
  useMutation(async () => {
    console.log(
      `[portal.api.notification.read] Setting notifications to read`,
    );

    const res = await fetchAndRaise(
      'portal.api.notification.read',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.NOTIFICATIONS_READ
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({})
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  }
  );

/* Organizations */

type OrganizationPermissions = {
  can_add_member: boolean,
  can_create_study: boolean,
  can_edit_data_streams: boolean
}

export type Organization = {
  name: string,
  id: string,
  kortex_jwt_secret: string,
  _permissions: OrganizationPermissions,
};

export const organizations: () => QueryResult<Organization[], Error> = () =>
  useQuery('organizations', async () => {
    console.log(`[portal.api.organizations] Fetching organizations ...`);

    const res = await fetchAndRaise(
      'portal.api.organizations',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${Routes.ME_MEMBERSHIP}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as Organization[];
  }, {
    retry: false,
  });

export const useOrganization: (organizationId: string, config?: QueryConfig<Organization, Error>) => QueryResult<Organization, Error> = (organizationId, config = {}) =>
  useQuery(['organization', organizationId], async () => {
    console.log(`[portal.api.organization] Fetching organization ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION, {
        organizationId
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as Organization;
  }, {
    retry: false,
    ...config
  });

type StudyMemberPermissions = {
  can_remove: boolean
  can_update_is_admin: boolean
}

export type StudyMember = {
  user_id: string,
  email: string,
  is_org_admin: boolean,
  is_study_admin: boolean,
  _permissions: StudyMemberPermissions
}

type OrganizationMemberPermissions = {
  can_remove: boolean
  can_update_is_admin: boolean
}

export type OrganizationMember = {
  user_id: string,
  email: string,
  is_owner: boolean,
  is_admin: boolean,
  _permissions: OrganizationMemberPermissions
}

export const useOrganizationMembers: (organizationId: string) => QueryResult<OrganizationMember[], Error> = (organizationId: string) =>
  useQuery(['organization-members', organizationId], async () => {
    console.log(`[portal.api.organization.members] Fetching organization members ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.members',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_MEMBERS, {
        organizationId
      })}`, {
      method: 'get',
      headers: await prepareHeaders()
    });

    return await res.json() as OrganizationMember[]
  }, {
    retry: false,
  });

export const useRemoveOrganizationMember: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
  organizationId: string;
  memberUserId: string;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, memberUserId }) => {
      console.log(
        `[portal.api.organization.members.remove] Removing ${memberUserId} from ${organizationId}...`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.members.remove',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_REMOVE_MEMBER,
          {
            organizationId,
            memberUserId
          },
        )}`,
        {
          method: 'delete',
          headers: await prepareHeaders(),
        },
      );

      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('organization-members')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );

export const useAddOrganizationMember: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
  organizationId: string;
  email: string;
  admin?: boolean
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, email }) => {
      email = email.trim()
      console.log(
        `[portal.api.organization.members.add] Adding ${email} (admin) to ${organizationId}...`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.members.add',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_ADD_MEMBER,
          {
            organizationId,
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ email })
        },
      );

      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('organization-members')
        await queryCache.invalidateQueries('organization-member-invites')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: (e) => {
        handlers && handlers.onError && handlers.onError(e);
      }
    },
    );

export const useUpdateOrganizationMember: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
  organizationId: string
  memberUserId: string
  admin: boolean
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, memberUserId, admin }) => {
      console.log(
        `[portal.api.organization.members.update] Updating ${memberUserId} to admin ${admin.toString()}...`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.members.update',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_UPDATE_MEMBER,
          {
            organizationId,
            memberUserId
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ admin })
        },
      );

      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('organization-members')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: (e) => {
        handlers && handlers.onError && handlers.onError(e);
      }
    },
    );

/** organization member invites */

type OrganizationMemberInvite = string  // represents their email

export const useOrganizationMemberInvites: (organizationId: string) => QueryResult<OrganizationMemberInvite[], Error> = (organizationId: string) =>
  useQuery(['organization-member-invites', organizationId], async () => {
    console.log(`[portal.api.organization.members] Fetching organization member invites ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.member.invites',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_MEMBER_INVITES, {
        organizationId
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as OrganizationMemberInvite[]
  }, {
    retry: false,
  });

export const useRemoveOrganizationMemberInvite: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
  organizationId: string;
  email: string;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, email }) => {
      console.log(
        `[portal.api.organization.member.invite.remove] Removing ${email} invite from ${organizationId}...`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.member.invite.remove',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_MEMBER_INVITES,
          {
            organizationId,
          },
        )}`,
        {
          method: 'delete',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ email })
        },
      );

      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('organization-member-invites')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );

export const useCreateOrganization: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, {
      name: string
      activationKey: string
    }, unknown> = (handlers) =>
        useMutation(async ({ name, activationKey }) => {
          console.log(
            `[portal.api.organization.add] Adding org ${name}...`,
          );
    
          const res = await fetchAndRaise(
            'portal.api.organization.add',
            `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
              Routes.ORGANIZATION_ADD,
              {
                name,
                activationKey,
              },
            )}`,
            {
              method: 'put',
              headers: await prepareHeaders({
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify({
                name,
                activation_key: activationKey,
              })
            },
          );
    
          return await res.json() as { id: string };
        }, {
          onSuccess: () => {
            handlers && handlers.onSuccess && handlers.onSuccess();
          },
          onError: (e) => {
            handlers && handlers.onError && handlers.onError(e);
          }
        },
    );

export const useAddOrganizationDevice: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
      organizationId: string
      activationKey: string
    }, unknown> = (handlers) =>
        useMutation(async ({ organizationId, activationKey }) => {
          console.log(
            `[portal.api.organization.devices.add] Adding device to ${organizationId}...`,
          );
    
          const res = await fetchAndRaise(
            'portal.api.organization.devices.add',
            `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
              Routes.ORGANIZATION_DATA_STREAM_ADD,
              {
                organizationId,
                activationKey,
              },
            )}`,
            {
              method: 'put',
              headers: await prepareHeaders({
                'Content-Type': 'application/json'
              }),
            },
          );
    
          return await res.json() as { success: boolean };
        }, {
          onSuccess: async () => {
            await queryCache.invalidateQueries('data-streams')
            handlers && handlers.onSuccess && handlers.onSuccess();
          },
          onError: (e) => {
            handlers && handlers.onError && handlers.onError(e);
          }
        },
        );

/** data streams */

export type DataTransform = {
  id: string
  company: string
  name: string
  integration: "survey" | "physical_device",
}

export type DataStreamStatus = {
  can_retry_upload: boolean,
  last_uploading: number,
  last_uploaded: number,
  next_ready: number[],
}

export type ConfigurationPatch = {
  high_priority_modules?: Set<number>
  flow_layout_sources: Record<string, number[][]>
  flow_layout_detectors: Record<string, number[][]>
  flow_locs_sources: Record<string, number[][]>
  flow_locs_detectors: Record<string, number[][]>
  flow_layout_eeg: [string,number,number][]
  wavelengths?: number[]
  landmark_labels?: string[]
  landmark_pos?: number[][]
}

export type DataStream = {
  id: string
  data_transform: DataTransform
  data_stream_status?: DataStreamStatus
  name: string,
  display_name: string,
  has_data: boolean
  is_hidden: boolean
  auto_assign?: boolean
  always_ready: boolean
  flow_configuration?: { // identifies physical device
    serial_number: string
    configuration_patch: ConfigurationPatch
    expected_module_count?: number
  }
  meta?: {
    is_flow_vr?: boolean, // identifies flow VR
  } & Partial<DataStreamSurvey>
  
}

export const useDataTransforms: () => QueryResult<DataTransform[], Error> = () =>
  useQuery(['data-transforms'], async () => {
    console.log(
      `[portal.api.organization.data-transforms] Fetching data transforms ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.data-transforms',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_TRANSFORMS,
        {},
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as DataTransform[];
  }, {
    retry: false,
  });

const useDataStreams: <DataStreamType extends DataStream = DataStream>(url: string) => QueryResult<DataStreamType[], Error> = <DataStreamType>(url: string) =>
  useQuery(['data-streams', url], async () => {
    console.log(`[portal.api.data-streams] Fetching ${url} data streams...`);

    const res = await fetchAndRaise(
      'portal.api.data-streams',
      url, {
      method: 'get',
      headers: await prepareHeaders()
    });

    return await res.json() as DataStreamType[]
  }, {
    retry: false,
  });

export const useAllDataStreams: (organizationId: string, studyId: string) => QueryResult<DataStream[], Error> = (organizationId: string, studyId: string) =>
  useDataStreams<StudyDataStream>(`${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_ALL_DATA_STREAMS, {
    organizationId,
    studyId,
  })}`)

export const useOrganizationDataStreams: (organizationId: string) => QueryResult<DataStream[], Error> = (organizationId: string) =>
  useDataStreams(`${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_DATA_STREAMS, {
    organizationId,
  })}`)

type StudyDataStreamPermissions = {
  can_update_is_hidden: boolean
  can_delete: boolean
  can_update_auto_assign: boolean
  can_edit_names: boolean
  can_toggle_always_ready: boolean
}

export type StudyDataStream = DataStream & {
  _permissions: StudyDataStreamPermissions
}

export const useStudyDataStreams: (organizationId: string, studyId: string) => QueryResult<StudyDataStream[], Error> = (organizationId: string, studyId: string) =>
  useDataStreams<StudyDataStream>(`${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_DATA_STREAMS, {
    organizationId,
    studyId,
  })}`)

export const useStudyDataStream: (dataStreamId: string, organizationId: string, studyId: string) => QueryResult<StudyDataStream, Error> = (dataStreamId: string, organizationId: string, studyId: string) =>
  useQuery(['data-stream', dataStreamId], async () => {
    console.log(`[portal.api.data-stream'] Fetching survey with id of ${dataStreamId}`);

    const res = await fetchAndRaise(
      'portal.api.data-stream',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM,
        {
          dataStreamId,
          organizationId,
          studyId
        },
      )}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as StudyDataStream;
  }, {
    retry: false,
  });

export const useParticipantDataStreams: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<DataStream[], Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useDataStreams(`${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_PARTICIPANT_DATA_STREAMS, {
    organizationId,
    studyId,
    virtualUserId,
  })}`)

export const useDataStreamDelete: (handlers?: MutationHandlers) => MutationResultPair<string, Error, Pick<DataStream, 'id'>, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId }) => {
    console.log(`[kernel.api.data-stream.delete] Removing (deleting) data stream ${dataStreamId} ...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.delete',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_DELETE,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'delete',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamToggleHidden: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, Pick<DataStream, 'is_hidden' | 'id'> & { organizationId: string, studyId: string }, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, is_hidden }) => {
    console.log(`[kernel.api.data-stream.toggle-hidden] Setting is_hidden to ${is_hidden.toString()}...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.toggle-hidden',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_UPDATE,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          is_hidden
        })
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['data-streams', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamToggleAutoAssign: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, Pick<DataStream, 'auto_assign' | 'id'>, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, auto_assign }) => {

    console.log(`[portal.api.data-stream.toggle-auto-assign] Setting auto_assign to ${auto_assign ? auto_assign.toString() : 'undefined'}...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.toggle-auto-assign',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_UPDATE,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          auto_assign
        })
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamToggleAlwaysAvailable: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean, always_ready: boolean }, Error, Pick<DataStream, 'id'>, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId }) => {

    console.log(`[portal.api.data-stream.toggle-always-ready] Toggling always ready ...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.toggle-always-ready',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_TOGGLE_ALWAYS_READY,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as { success: boolean, always_ready: boolean };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamUpdateName: (handlers?: MutationHandlers) => MutationResultPair<string, Error, Pick<DataStream, 'name' | 'id'> & { organizationId: string, studyId: string }, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, name }) => {
    console.log(`[kernel.api.data-stream.name] Setting name to ${name.toString()}...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.name',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_UPDATE,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name
        })
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamUpdateDisplayName: (handlers?: MutationHandlers) => MutationResultPair<string, Error, Pick<DataStream, 'display_name' | 'id'>, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, display_name }) => {
    console.log(`[kernel.api.data-stream.display-name] Setting display_name to ${display_name.toString()}...`);

    const res = await fetchAndRaise(
      'portal.api.data-stream.display-name',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_UPDATE,
        {
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          display_name
        })
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDataStreamCreate: (handlers?: MutationHandlers) => MutationResultPair<string, Error, { organizationId: string, studyId: string, file: File, auto_assign?: boolean }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, file, auto_assign = false }) => {
    console.log(`[portal.api.data-stream.create] Creating data stream...`);

    // manipulate file to correct format
    const text = await file.text();
    const obj: Record<string, unknown> = JSON.parse(text) as Record<string, unknown>;
    const newObj = { // only study owned surveys supported today
      data_transform_id: "survey",
      owner: { type: "study", org_id: organizationId, id: studyId },
      name: obj.name,
      meta: obj,
      auto_assign
    }

    const res = await fetchAndRaise(
      'portal.api.data-stream.create',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_CREATE,
        {
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': file.type
        }),
        body: JSON.stringify(newObj)
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export type DataStreamSurveyStepQuestionChoice = {
  label: string
  value: string
}

export type DataStreamSurveyStepQuestionRange = {
  maximumLabel: string
  maximumValue: number
  minimumLabel: string
  minimumValue: number
  step: number
  ticks: number[]
}

export enum DataStreamSurveyStepQuestionType {
  MultipleChoice = "MultipleChoice",
  MultipleChoiceRadio = "MultipleChoiceRadio",
  Range = "Range",
  Input = "Input",
  MultipleSelect = "MultipleSelect",
  Number = "Number",
  Time = "Time",
  Day = "Day",
  Month = "Month",
  Year = "Year",
  Email = "Email",
  PhoneNumber = "PhoneNumber",
  Instruction = "Instruction",
}

export const isMultipleQuestionType = (type: DataStreamSurveyStepQuestionType | string): boolean =>
  type === DataStreamSurveyStepQuestionType.MultipleChoice ||
  type === DataStreamSurveyStepQuestionType.MultipleChoiceRadio ||
  type === DataStreamSurveyStepQuestionType.MultipleSelect

export type DataStreamSurveyStepQuestion = {
  id: string
  label: string // name of field, appears on top
  title: string // description of field, appears below label
  type: DataStreamSurveyStepQuestionType
  choices?: DataStreamSurveyStepQuestionChoice[]
  range?: DataStreamSurveyStepQuestionRange
  ineligible_answers?: string[]
}

export type DataStreamSurveyStep = {
  id: string
  questions: DataStreamSurveyStepQuestion[]
}

export type DataStreamSurvey = {
  steps: DataStreamSurveyStep[]
  questionsRequired?: boolean
}

export enum SurveyType {
  Enrollment = 'enrollment',
  Session = 'session',
  Consent = 'consent'
}

export const useDataStreamCreateWithJson: (handlers?: MutationHandlers) => MutationResultPair<string, Error, { organizationId: string, studyId: string, json: string, auto_assign?: boolean, is_hidden?: boolean }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, json, auto_assign = false, is_hidden = false }) => {
    console.log(`[portal.api.data-stream.create] Creating data stream with json...`);

    const obj: Record<string, unknown> = JSON.parse(json) as Record<string, unknown>
    const newObj = { // only study owned surveys supported today
      data_transform_id: "survey",
      owner: { type: "study", org_id: organizationId, id: studyId },
      name: obj.name,
      meta: obj,
      auto_assign,
      is_hidden
    }

    const res = await fetchAndRaise(
      'portal.api.data-stream.create',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_CREATE,
        {
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(newObj)
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

  export const useDataStreamUpdateSurvey: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, { organizationId: string, studyId: string, dataStream: Pick<DataStream, 'id' | 'name' | 'meta' | 'auto_assign' | 'is_hidden'> }, unknown> = (handlers) =>
  useMutation(async ({ dataStream}) => {
    console.log(`[portal.api.data-stream.update] Updating data stream ${dataStream.id} with...`);
    const res = await fetchAndRaise(
      'portal.api.data-stream.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DATA_STREAM_UPDATE,
        {
          dataStreamId: dataStream.id
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name: dataStream.name,
          meta: dataStream.meta,
          is_hidden: dataStream.is_hidden,
          auto_assign: dataStream.auto_assign
        })
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['data-streams', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const studyParticipantToggleRetakeDataStream: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, Pick<DataStream, 'id'> & { organizationId: string, studyId: string, virtualUserId: string }, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, organizationId, studyId, virtualUserId }) => {
    console.log(`[kernel.api.organization.study.participant.toggleRetake] Retake device: ${dataStreamId}`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.participant.toggleRetake',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PARTICIPANT_TOGGLE_CAN_RETRY_UPLOAD_DATA_STREAM,
        {
          organizationId,
          studyId,
          virtualUserId,
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const studyParticipantSetScheduleDataStream: (handlers?: MutationHandlers) => MutationResultPair<string, Error, Pick<DataStream, 'id'> & { organizationId: string, studyId: string, virtualUserId: string, nextReady: number[] }, unknown> = (handlers) =>
  useMutation(async ({ id: dataStreamId, organizationId, studyId, virtualUserId, nextReady }) => {
    console.log(`[kernel.api.organization.study.participant.setSchedule] Set schedule: ${dataStreamId}`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.participant.setSchedule',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PARTICIPANT_SET_SCHEDULE_DATA_STREAM,
        {
          organizationId,
          studyId,
          virtualUserId,
          dataStreamId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ next_ready: nextReady })
      },
    );

    return await res.json() as string;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('data-streams')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/*** checklists ***/

export type ChecklistStepType = 'session' | 'survey'

export type Step = {
  step_type: ChecklistStepType
  id: string
  display_name: string
} & Record<string, string>

export type Checklist = {
  id: string
  name: string
  can_delete: boolean
  can_edit: boolean
  steps?: Step[]
}

export type ChecklistLogData = {
  step_id: string
  step_type: string
  display_name: string
  checked?: boolean
  completed_at?: number
  notes?: string
  sessions?: {
    id: string
    user_id: string
    created_by_clinic?: string
  }[]
}

export type ChecklistLogValues = Pick<ChecklistLogData, 'step_id' | 'checked' | 'notes'>

export type ChecklistLog = {
  id: string
  checklist_id: string
  checklist_name: string
  member_user_id: string
  virtual_user_id: string
  is_complete: boolean
  missed: boolean
  data: ChecklistLogData[]
}

export const useChecklists: (organizationId: string, studyId: string) => QueryResult<Checklist[], Error> = (organizationId: string, studyId: string) =>
  useQuery(['study-checklists', organizationId, studyId], async () => {
    console.log(
      `[portal.api.organization.study.checklists] Fetching checklists for study ${studyId} for organization ${organizationId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.study.checklists',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_CHECKLISTS,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as Checklist[];
  }, {
    retry: false,
  });

  export const useChecklist: (organizationId: string, studyId: string, checklistId: string) => QueryResult<Checklist, Error> = (organizationId: string, studyId: string, checklistId: string) =>
  useQuery(['study-checklist', organizationId, studyId, checklistId], async () => {
    console.log(
      `[portal.api.organization.study.checklist] Fetching checklist ${checklistId} for study ${studyId} for organization ${organizationId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.study.checklist',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_CHECKLIST_GET,
        {
          organizationId,
          studyId,
          checklistId
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as Checklist;
  }, {
    retry: false,
  });

export const useAddChecklist: (handlers?: MutationHandlers) => MutationResultPair<{success: boolean, checklist_id: string}, Error, { organizationId: string, studyId: string, name: string, steps: Step[] }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, name, steps }) => {
  console.log(`[kernel.api.organization.study.checklist.add] Adding checklist ${name} to study ${studyId} organization ${organizationId}...`);

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.add',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_CREATE,
      {
        organizationId,
        studyId
      },
    )}`,
    {
      method: 'PUT',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        name,
        steps
      })
    },
  );

  return await res.json() as {success: boolean, checklist_id: string};
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('study-checklists')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useDeleteChecklist: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, checklistId: string }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, checklistId }) => {
  console.log(`[kernel.api.organization.study.checklist.delete] Deleting checklist ${checklistId} in study ${studyId} organization ${organizationId}...`);

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.delete',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_DELETE,
      {
        organizationId,
        studyId,
        checklistId
      },
    )}`,
    {
      method: 'delete',
      headers: await prepareHeaders()
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('study-checklists')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})
export const useUpdateChecklist: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, checklistId: string, name: string, steps: Step[] }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, checklistId, name, steps }) => {
  console.log(`[kernel.api.organization.study.checklist.update] Updating checklist ${checklistId} in study ${studyId} organization ${organizationId}...`);

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.update',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_UPDATE,
      {
        organizationId,
        studyId,
        checklistId
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        name,
        steps
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('study-checklists')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

type ChecklistLogsResult = {
  protocol: string
  checklists: ChecklistLog[]
  protocols: Pick<Protocol, 'id' | 'name'>[]
}

export const useChecklistLogs: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<ChecklistLogsResult, Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
useQuery(['study-checklist-logs', organizationId, studyId, virtualUserId], async () => {
  console.log(
    `[portal.api.organization.study.checklist] Fetching checklist logs for participant ${virtualUserId} in study ${studyId} for organization ${organizationId} ...`,
  );

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.log',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_LOGS_GET,
      {
        organizationId,
        studyId,
        virtualUserId
      },
    )}`,
    {
      method: 'get',
      headers: await prepareHeaders(),
    },
  );

  return await res.json() as ChecklistLogsResult;
}, {
  retry: false,
});

export const useUpdateChecklistLog: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, checklistLogId: string, values: ChecklistLogValues[] }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, checklistLogId, values }) => {
  console.log(`[kernel.api.organization.study.checklist.log.update] Updating log for checklist ${checklistLogId} to study ${studyId} organization ${organizationId}...`);

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.log.update',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_LOG_UPDATE,
      {
        organizationId,
        studyId,
        checklistLogId
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        values
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('study-checklist-logs')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useSetChecklistLogMissed: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, checklistLogId: string, missed: boolean }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, checklistLogId, missed }) => {
  console.log(`[kernel.api.organization.study.checklist.log.missed] Updating log missed ${missed ? "true" : "false"} for checklist ${checklistLogId} to study ${studyId} organization ${organizationId}...`);

  const res = await fetchAndRaise(
    'portal.api.organization.study.checklist.log.missed',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ORGANIZATION_STUDY_CHECKLIST_LOG_MISSED,
      {
        organizationId,
        studyId,
        checklistLogId
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        missed
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('study-checklist-logs')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

/*** Protocols ***/

export type ProtocolChecklist = { id: string, name: string }

export type Protocol = {
  id: string
  name: string
  checklists: ProtocolChecklist[]
  can_edit: boolean
  can_delete: boolean
}

export type Protocols = {
  protocols: Protocol[]
}

export const useProtocols: (organizationId: string, studyId: string) => QueryResult<Protocols, Error> = (organizationId: string, studyId: string) =>
  useQuery(['study-protocols', organizationId, studyId], async () => {
    console.log(`[portal.api.organization.study.protocols] Fetching protocols for study ${studyId} for organization ${organizationId} ...`)

    const res = await fetchAndRaise(
      'portal.api.organization.study.protocols',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PROTOCOLS,
        {
          organizationId,
          studyId
        },
      )}`,
      {
        method: 'GET',
        headers: await prepareHeaders()
      },
    );

    return await res.json() as Protocols
  }, {
    retry: false,
  });

export const useAddProtocol: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, name: string, checklists: ProtocolChecklist[] }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, name, checklists }) => {
    console.log(`[portal.api.organization.study.protocol.add] Adding protocol ${name} to study ${studyId} and organization ${organizationId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.protocol.add',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PROTOCOL_CREATE,
        {
          organizationId,
          studyId
        },
      )}`,
      {
        method: 'POST',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name,
          checklists,
        })
      },
    );

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-protocols')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDeleteProtocol: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, protocolId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, protocolId }) => {
    console.log(`[portal.api.organization.study.protocol.delete] Deleting protocol ${protocolId} in study ${studyId} in organization ${organizationId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.protocol.delete',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PROTOCOL_DELETE,
        {
          organizationId,
          studyId,
          protocolId
        },
      )}`,
      {
        method: 'DELETE',
        headers: await prepareHeaders()
      },
    );

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-protocols')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useUpdateProtocol: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, protocolId: string, name: string, checklists: ProtocolChecklist[] }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, protocolId, name, checklists }) => {
    console.log(`[portal.api.organization.study.protocol.update] Updating protocol ${protocolId} in study ${studyId} in organizaiton ${organizationId}`)

    const res = await fetchAndRaise(
      'portal.api.organization.study.protocol.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PROTOCOL_UPDATE,
        {
          organizationId,
          studyId,
          protocolId
        },
      )}`,
      {
        method: 'PUT',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name,
          checklists,
        })
      },
    );

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-protocols');
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useApplyProtocol: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, protocolId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, protocolId }) => {
    console.log(`[portal.api.organization.study.virtualUser.protocol] Applying protocol ${protocolId} in virtual user ${virtualUserId} study ${studyId} in organizaiton ${organizationId}`)

    const res = await fetchAndRaise(
      'portal.api.organization.study.virtualUser.protocol',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_VIRTUAL_USER_PROTOCOL,
        {
          organizationId,
          studyId,
          virtualUserId,
        },
      )}`,
      {
        method: 'PUT',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          protocol_id: protocolId,
        })
      },
    );

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (data, { organizationId, studyId, virtualUserId }) => {
      await queryCache.invalidateQueries(['study-checklist-logs', organizationId, studyId, virtualUserId]);
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

  export const useProtocolExport: (handlers?: MutationHandlers) => MutationResultPair<void, Error, {
    organizationId: string
    studyId: string
    protocolId: string
    protocolName: string
  }, unknown> = (handlers) =>
      useMutation(async ({ organizationId, studyId, protocolId, protocolName }) => {
        console.log(
          `[portal.api.organization.study.protocol.export] Protocol export for protocol ${protocolId} study ${studyId} and organization ${organizationId}`,
        );
  
        const res = await fetchAndRaise(
          'portal.api.organization.study.protocol.export',
          `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
            Routes.ORGANIZATION_STUDY_PROTOCOL_EXPORT,
            {
              organizationId,
              studyId,
              protocolId,
            },
          )}`,
          {
            method: 'get',
            headers: await prepareHeaders({
              'Content-Type': 'application/json'
            }),
          },
        );
  
        const json = await res.json() as Record<string, string>
        downloadJson(json, protocolName)
      }, {
        onSuccess: () => {
          handlers && handlers.onSuccess && handlers.onSuccess();
        },
        onError: () => {
          handlers && handlers.onError && handlers.onError();
        }
      },
    );
  
  export const useProtocolsImport: (handlers?: MutationHandlers) => MutationResultPair<{ protocol_id: string }, Error, {
    organizationId: string
    studyId: string
    file: File;
  }, unknown> = (handlers) =>
      useMutation(async ({ organizationId, studyId, file }) => {
        console.log(
          `[portal.api.organization.study.protocols.import] Protocols import for study ${studyId} and organization ${organizationId}`,
        );
  
        const res = await fetchAndRaise(
          'portal.api.organization.study.protocols.import',
          `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
            Routes.ORGANIZATION_STUDY_PROTOCOLS_IMPORT,
            {
              organizationId,
              studyId,
            },
          )}`,
          {
            method: 'post',
            headers: await prepareHeaders({
              'Content-Type': 'application/json'
            }),
            body: await file.text(),
          },
        );
  
        return await res.json() as { protocol_id: string };
      }, {
        onSuccess: async () => {
          await queryCache.invalidateQueries('study-protocols');
          await queryCache.invalidateQueries('study-checklists');
          handlers && handlers.onSuccess && handlers.onSuccess();
        },
        onError: () => {
          handlers && handlers.onError && handlers.onError();
        }
      },
      );

/*** organization (session) transform ***/

type TransformPipelineResponse = {
  batch_job_ids: Record<string, string>
}

export const useSessionTransformPipeline: (handlers?: MutationHandlers) => MutationResultPair<TransformPipelineResponse, Error, { organizationId: string, studyId: string, sessionId: string, pipeline: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, sessionId, pipeline }) => {
    console.log(`[portal.api.organization.transform-pipeline] Starting ${pipeline} transform pipeline for session ${sessionId} in study ${organizationId} ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.transform-pipeline',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_TRANSFORM_PIPELINE, {
        organizationId,
        studyId,
      })}`, {
      method: 'PUT',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ session_ids: [sessionId], pipeline_name: pipeline })
    })

    return await res.json() as TransformPipelineResponse;
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  })

export type TransformPipelineStatus = {
  running: boolean,
  last_status?: string,
  last_status_at?: number,
  logs: Array<{
    log_time: number,
    level: string,
    message: string,
  }>
}

type TransformPipelineStatusResponse = Record<string, TransformPipelineStatus>

/**
 * Poll pipeline status
 */
export const useSessionTransformPipelineStatus: (organizationId: string, studyId: string, sessionId: string, pipelineNames: string[]) => QueryResult<TransformPipelineStatusResponse, Error> = (organizationId: string, studyId: string, sessionId: string, pipelineNames: string[]) =>
  useQuery(['transform-pipeline-status', organizationId, studyId, sessionId, JSON.stringify(pipelineNames)], async () => {
    console.log(
      `[portal.api.organization.transform-pipeline.status] Checking pipeline ${JSON.stringify(pipelineNames)} status in ${organizationId} ${studyId} ${sessionId} ...`,
    );

    if (pipelineNames.length === 0) {
      return {}
    }

    const res = await fetchAndRaise(
      'portal.api.organization.transform-pipeline.status',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.STUDY_TRANSFORM_PIPELINE_STATUS,
        {
          organizationId,
          studyId,
          sessionId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ pipeline_names: pipelineNames })
      },
    );

    return await res.json() as TransformPipelineStatusResponse;
  }, {
    refetchInterval: 5000,
  });

export const AnalysisPipelineStatuses = {
  my_running: "My Running",
  my_latest: "My Latest",
  running: "All Running",
  latest: "All Latest",
}
export type AnalysisPipelineStatusType = keyof typeof AnalysisPipelineStatuses;
type AnalysisPipelineStatusResponse = { jobs: Record<string, Record<string, TransformPipelineStatus>> };

export const useAnalysisPipelineStatus: (organizationId: string, studyId: string, status: AnalysisPipelineStatusType) => QueryResult<AnalysisPipelineStatusResponse, Error> = (organizationId: string, studyId: string, status: AnalysisPipelineStatusType) =>
  useQuery(['analysis-status', organizationId, studyId, status], async () => {
    console.log(
      `[portal.api.organization.analysis.status] Checking pipeline status in ${organizationId} ${studyId} ${status} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.analysis.status',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ANALYSIS_PIPELINE_STATUS,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ status })
      },
    );

    return await res.json() as AnalysisPipelineStatusResponse;
  }, {
    refetchInterval: 5000,
  });

/*** studies ***/

type StudyPermissions = {
  can_modify: boolean,
  can_add_member: boolean,
  can_remove_member: boolean,
  can_add_data_stream: boolean,
  can_close: boolean,
  can_retake_data_streams: boolean,
  can_invite_participant: boolean,
  invite_code_enabled: boolean,
  can_view_participant_status: boolean,
  can_manage_participant_status: boolean,
  can_enroll: boolean,
  can_add_notes: boolean,
  can_add_admin_only_notes: boolean,
  can_manage_templates: boolean,
  can_manage_experiments: boolean,
  can_manage_participant_results_template: boolean,
  can_schedule: boolean,
  can_delete_events: boolean,
  can_manage_checklist: boolean,
  can_view_checklists: boolean,
  can_clinic: boolean,
  can_message: boolean,
  can_rate: boolean,
  can_pay: boolean,
  can_view_pipeline_statuses: boolean,
  can_view_surveys: boolean,
  can_edit_study_image: boolean,
  can_add_consent_survey: boolean,
  can_view_study_reports: boolean,
}

export type Study = {
  name: string,
  id: string,
  organization: Organization,
  is_complete: boolean,
  participant_prefix: string,
  participant_size: number,
  description: string,
  is_enrolling: boolean,
  enrollment_survey?: DataStreamSurvey,
  consent_survey?: DataStreamSurvey,
  _permissions: StudyPermissions,
  image: string,
  clinic_enabled: boolean,
};

export const studies: (organizationId?: string, queryParams?: URLSearchParams) => QueryResult<Study[], Error> = (organizationId?: string, queryParams?: URLSearchParams) =>
  useQuery(['studies', organizationId, queryParams?.toString()], async () => {
    console.log(`[portal.api.organization.studies] Fetching studies for organization ${organizationId || 'N/A'} ...`);

    const url = new URL(`${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDIES, {
      organizationId
    })}`)

    if (queryParams) {
      url.search = queryParams.toString()
    }

    const res = await fetchAndRaise(
      'portal.api.organization.studies',
      url.toString(), {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as Study[];
  }, {
    retry: false,
    enabled: organizationId,
  });

export const useStudy: (organizationId?: string, studyId?: string) => QueryResult<Study, Error> = (organizationId?: string, studyId?: string) =>
  useQuery(['study', organizationId, studyId], async () => {
    console.log(`[portal.api.organization.study] Fetching study ${studyId || 'N/A'} for organization ${organizationId || 'N/A'} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY, {
        organizationId,
        studyId
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as Study;
  }, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: organizationId && studyId,
  });

export const useStudyMembers: (organizationId: string, studyId: string) => QueryResult<StudyMember[], Error> = (organizationId: string, studyId) =>
  useQuery(['study-members', organizationId, studyId], async () => {
    console.log(
      `[portal.api.organization.study.members] Fetching members for study ${studyId} for organization ${organizationId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.study.members',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_MEMBERS,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as StudyMember[];
  }, {
    retry: false,
  });

export const useStudyAvailableMembers: (organizationId: string, studyId: string) => QueryResult<StudyMember[], Error> = (organizationId: string, studyId) =>
  useQuery(['study-available-members', organizationId, studyId], async () => {
    console.log(
      `[portal.api.organization.study.members.available] Fetching available members for study ${studyId} for organization ${organizationId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.study.members.available',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_AVAILABLE_MEMBERS,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as StudyMember[];
  }, {
    retry: false,
  });

export const useAddStudyMember: (handlers?: MutationHandlers) => MutationResultPair<StudyMember, Error, { organizationId: string, studyId: string, user_id: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, user_id }) => {
    console.log(`[kernel.api.organization.study.members.add] Adding member ${user_id} study ${studyId} organization ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.members.add',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_MEMBER,
        {
          organizationId,
          studyId,
          memberUserId: user_id
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
      },
    );

    return await res.json() as StudyMember;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-members')
      await queryCache.invalidateQueries('study-available-members')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDeleteStudyMember: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, memberUserId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, memberUserId }) => {
    console.log(
      `[portal.api.organization.study.member.delete] Deleting member ${memberUserId}...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.study.members.delete',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        // Add/Delete
        Routes.ORGANIZATION_STUDY_MEMBER,
        {
          organizationId,
          studyId,
          memberUserId
        },
      )}`,
      {
        method: 'delete',
        headers: await prepareHeaders()
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-members')
      await queryCache.invalidateQueries('study-available-members')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  },
  );

export const useUpdateStudyMember: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, {
  organizationId: string
  studyId: string
  memberUserId: string
  admin: boolean
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId, memberUserId, admin }) => {
      console.log(
        `[portal.api.study.members.update] Updating ${memberUserId} to admin ${admin.toString()}...`,
      );

      const res = await fetchAndRaise(
        'portal.api.study.members.update',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_STUDY_MEMBER,
          {
            organizationId,
            studyId,
            memberUserId
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ admin })
        },
      );

      return await res.json() as { success: boolean };
    }, {
      onSuccess: async () => {
        await queryCache.invalidateQueries('study-members')
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: (e) => {
        handlers && handlers.onError && handlers.onError(e);
      }
    },
    );


export const useCreateStudy: (handlers?: MutationHandlers) => MutationResultPair<Study, Error, { organizationId: string, name: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, name }) => {
    console.log(`[kernel.api.organization.study.create] Creating study ${organizationId} ${name}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.create',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_CREATE_STUDY,
        {
          organizationId
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name,
        })
      },
    );

    return await res.json() as Study;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('studies')
      await queryCache.invalidateQueries('study')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useCloseStudy: (handlers?: MutationHandlers) => MutationResultPair<Study, Error, { organizationId: string, name: string, studyId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, name, studyId }) => {
    console.log(`[kernel.api.organization.study.close] Closeing study ${studyId} organization ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.close',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY,
        {
          organizationId,
          studyId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          name,
          is_complete: true
        })
      },
    );

    return await res.json() as Study;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('studies')
      await queryCache.invalidateQueries('study')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useUpdateStudy: (handlers?: MutationHandlers) => MutationResultPair<Study, Error, { organizationId: string, studyId: string, name?: string, participantPrefix?: string, participantSize?: number, description?: string, isEnrolling?: boolean, enrollmentSurvey?: DataStreamSurvey, consentSurvey?: DataStreamSurvey, clinicPassword?: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, name, participantPrefix, participantSize, description, isEnrolling, enrollmentSurvey, consentSurvey, clinicPassword }) => {
    console.log(`[kernel.api.organization.study.update] Updating study ${studyId} organization ${organizationId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY,
        {
          organizationId,
          studyId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          ...(typeof (name) === 'string' && { name }),
          ...(typeof (participantPrefix) === 'string' && { participant_prefix: participantPrefix }),
          ...(typeof (participantSize) === 'number' && { participant_size: participantSize }),
          ...(typeof (description) === 'string' && { description }),
          ...(typeof (isEnrolling) === 'boolean' && { is_enrolling: isEnrolling }),
          ...(enrollmentSurvey && { enrollment_survey: enrollmentSurvey }),
          ...(consentSurvey && { consent_survey: consentSurvey }),
          ...(clinicPassword && { clinic_password: clinicPassword }),
        })
      },
    );

    return await res.json() as Study;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('studies')
      await queryCache.invalidateQueries('study')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  })

export const useUpdateStudyImage: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean, action: string }, Error, { organizationId: string, studyId: string, image: File | '' }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, image }) => {
    console.log(`[kernel.api.organization.study.update.image] Updating study ${studyId} organization ${organizationId} cover image...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.update.image',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_UPLOAD_IMAGE,
        {
          organizationId,
          studyId
        }
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'image/png'
        }),
        body: image // .png
      })
    return await res.json() as { success: boolean, action: string };
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('studies')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  });

/** study experiments */

export type Experiment = {
  id: string
  name: string
  display_name: string
  study_id: string
  participant_results_template: string
}

export const useExperiments: (organizationId: string, studyId: string) => QueryResult<Experiment[], Error> = (organizationId: string, studyId: string) =>
  useQuery(['experiments', organizationId, studyId], async () => {
    console.log(`[portal.api.organization.study.experiments] Fetching experiments for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.experiments',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_EXPERIMENTS, {
        organizationId,
        studyId
      })}?full=true`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return (await res.json() as { experiments: Experiment[] }).experiments;
  }, {
    retry: false,
  });

export const useAddExperiment: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, experiment: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, experiment }) => {
    console.log(`[kernel.api.organization.study.experiment.add] Adding experiment ${experiment} to study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.experiment.add',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_EXPERIMENT_CREATE,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ experiment })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['experiments', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useUpdateExperiment: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, experimentId: string, name: string, displayName: string, participant_results_template: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, experimentId, name, displayName, participant_results_template }) => {
    console.log(`[kernel.api.organization.study.experiment.update] Updating experiment ${name} from study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.experiment.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_EXPERIMENT_UPDATE,
        {
          organizationId,
          studyId,
          experimentId
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ name, display_name: displayName, participant_results_template })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['experiments', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useRemoveExperiment: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, experimentId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, experimentId }) => {
    console.log(`[kernel.api.organization.study.experiment.remove] Removing experiment ${experimentId} from study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.experiment.remove',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_EXPERIMENT_DELETE,
        {
          organizationId,
          studyId,
          experimentId
        },
      )}`,
      {
        method: 'delete',
        headers: await prepareHeaders()
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['experiments', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/*** study sessions ***/

type StudySessionPermissions = {
  can_download: boolean
}

export type StudySession = Session & {
  _permissions: StudySessionPermissions
}

export type StudySessions = {
  data_streams: Record<string, DataStream>,
  sessions: StudySession[],
  total_session_count: number
}

export const useStudySessions: (organizationId: string, studyId: string) => QueryResult<StudySessions, Error> = (organizationId: string, studyId: string) =>
  useQuery(['study-sessions', organizationId, studyId], async () => {
    console.log(`[portal.api.study.sessions] Fetching study sessions ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.study.sessions',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_SESSIONS, {
        organizationId,
        studyId
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as StudySessions
  }, {
    retry: false,
  });

export type StudySessionsFilter = Partial<{
  id: string
  start_time: number
  end_time: number
  number: string
  experiment: string
  description: string
  name: string
  allow_invalid: boolean
  include_withdraw: boolean
  participant: string
  created_by: string
  min_duration: number
  max_duration: number
  data_transform_id: string
  data_stream_id: string
}> & { offset: number, limit: number }

export const useFilteredStudySessions: (organizationId: string, studyId: string, filter?: StudySessionsFilter) => QueryResult<StudySessions, Error> = (organizationId, studyId, filter = { limit: 14, offset: 0 }) =>
  useQuery(['study-filtered-sessions', studyId, ...Object.keys(filter).length > 0 ? Object.values(filter) : 'EMPTY_FILTER'], async () => {
    console.log(`[portal.api.study.filtered-sessions] Fetching filtered study sessions ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.study.filtered-sessions',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_SESSIONS, {
        organizationId,
        studyId,
      })}`, {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ filter })
    });

    return await res.json() as StudySessions
  }, {
    retry: false,
  });

type CreateSessionResponse = {
  id: string
  upload_token: string
}

export const useCreateSession: (handlers?: MutationHandlers) => MutationResultPair<CreateSessionResponse, Error,  { clinicToken?: string, studyId?: string, virtualUserId: string, meta: Record<string, string>, checklistLogId?: string, checklistLogStepId?: string, createdByClinic?: string, participantPhoto?: string, participantPhotoLandmarks?: string }, unknown> = (handlers) =>
  useMutation(async ({ clinicToken, studyId, virtualUserId, meta, checklistLogId, checklistLogStepId, createdByClinic, participantPhoto, participantPhotoLandmarks }) => {
    console.log(`[portal.api.organization.study.session.create] Creating session on ${virtualUserId} ${JSON.stringify(meta)} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.session.create',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        clinicToken ? Routes.CLINIC_SESSION : Routes.ORGANIZATION_STUDY_CREATE_SESSION,
        {
          virtualUserId,
          ...(clinicToken && { studyId }),
        },
      )}`,
        {
          method: 'post',
          headers: await prepareHeaders({
            'Content-Type': 'application/json',
            ...(clinicToken && { 'x-kernel-clinic': clinicToken }),
          }),
          body: JSON.stringify({
            meta,
            ...(checklistLogId && {
              checklist_log_id: checklistLogId,
              checklist_log_step_id: checklistLogStepId,
              created_by_clinic: createdByClinic,
            }),
            ...(participantPhoto && participantPhotoLandmarks && {
              participant_photo: participantPhoto,
              participant_photo_landmarks: participantPhotoLandmarks,
            }),
          })
        },
      );

    return await res.json() as CreateSessionResponse;
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('study-sessions')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useRecentUploadingCount: (studyId: string, organizationId?: string, clinicToken?: string) => QueryResult<{ recent_uploading_count: number }, Error> = (studyId, organizationId, clinicToken) =>
  useQuery(['recent-uploading-count', studyId, organizationId, clinicToken], async () => {
    console.log(`[portal.api.recent-uploading-count] Fetching recent uploading count for study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.recent-uploading-count',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        clinicToken ? Routes.CLINIC_RECENT_UPLOADING_COUNT : Routes.ORGANIZATION_RECENT_UPLOADING_COUNT,
        {
          studyId,
          ...(organizationId && { organizationId }),
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders({
          ...(clinicToken && { 'x-kernel-clinic': clinicToken }),
        }),
      },
    );

    return await res.json() as { recent_uploading_count: number };
  }, {
    refetchInterval: 30_000,
  });

/** Participants */

export const ParticipantStatus = {
  candidate: {
    text: "Candidate",
    color: "default",
    hex: "#434343",
  },
  screening: {
    text: "Screening",
    color: "processing",
    hex: "#177ddc",
  },
  screen_fail: {
    text: "Screen Fail",
    color: "error",
    hex: "#d32029",
  },
  eligible: {
    text: "Eligible",
    color: "processing",
    hex: "#177ddc",
  },
  enrolled: {
    text: "Enrolled",
    color: "success",
    hex: "#49aa19",
  },
  active: {
    text: "Active",
    color: "success",
    hex: "#49aa19",
  },
  evaluable: {
    text: "Evaluable",
    color: "success",
    hex: "#49aa19",
  },
  withdraw: {
    text: "Withdraw",
    color: "error",
    hex: "#d32029",
  },
  lost_to_follow_up: {
    text: "Lost to follow up",
    color: "error",
    hex: "#d32029",
  },
  complete: {
    text: "Complete",
    color: "success",
    hex: "#49aa19",
  },
}

type ParticipantPermissions = {
  can_deactivate: boolean
  can_rename: boolean
  can_reinvite: boolean
  can_view_status: boolean
  can_manage_status: boolean
  can_email: boolean
  can_sms: boolean
  can_manage_documents: boolean
  can_pay: boolean
  can_pay_paypal: boolean
  can_pay_venmo: boolean
  can_pay_manual: boolean
  can_view_study_reports: boolean,
  can_manage_study_reports: boolean,
}

export type StudySurveyAnswer = {
  step_id: string
  question_id: string
  answered_at: number
  answer: string
}

export type Participant = {
  participant_id: string
  // id is virtual_user_id
  id: string
  created_at: number
  study: Study
  organization: Organization
  active: boolean
  pending: boolean
  _permissions: ParticipantPermissions
  status: keyof typeof ParticipantStatus // available on lists
  statuses: Array<{ id: string, created_at: number, created_by_user_email: string, status: string, status_reason: string }> // available on details
  enrollment_survey_answers?: StudySurveyAnswer[] // available for study admins on details
  requires_attention: boolean
}

export const ICFStatusMapping = {
  created: "ICF created",
  participant_delivered: "Participant delivered ICF",
  participant_signed: "Participant signed ICF",
  researcher_delivered: "Researcher delivered ICF",
  researcher_signed: "Researcher signed ICF",
  completed: "ICF complete",
  declined: "ICF declined"
} as const

export type ICFStatus = keyof typeof ICFStatusMapping | null

export type ParticipantMessage = {
  id: string
  message_type: "chat" | "email" | "sms"
  message_direction: "inbound" | "outbound"
  member_user_email: string
  body: string
  viewed_by_recipient: boolean
  created_at: number
  updated_at?: number
  deleted_at?: number
  virtual_user_id: string;
  member_user_id?: string;
  original_source?: string;
  tracking_code?: string;
  sender: string;
}

export type ParticipantListItem = Participant & {
  checklists_complete: number
  checklists_partially_complete: number
  icf_status: ICFStatus
  messages_require_attention: boolean
  rating?: number
  w9_status: boolean
  message: ParticipantMessage
};

export type ParticipantFilter = {
  status: keyof typeof ParticipantStatus | ""
  participant_id?: string
}

type Participants = {
  participants: ParticipantListItem[]
}

export const useParticipants: (organizationId: string, studyId: string, filter?: ParticipantFilter) => QueryResult<Participants, Error> = (organizationId: string, studyId: string, filter: ParticipantFilter = { status: "" }) =>
  useQuery(['participants', organizationId, studyId, filter], async () => {
    console.log(`[portal.api.organization.study.participants] Fetching participants for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.participants',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_PARTICIPANTS, {
        organizationId,
        studyId
      })}`, {
      method: 'put',
      headers: await prepareHeaders(
        {
          'Content-Type': 'application/json'
        }
      ),
      body: JSON.stringify(filter)
    });

    return await res.json() as Participants;
  }, {
    retry: false,
  });

export const useParticipant: (organizationId: string, studyId: string, virtualUserId?: string) => QueryResult<Participant, Error> = (organizationId: string, studyId: string, virtualUserId?: string) =>
  useQuery(['participant', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.organization.study.participant] Fetching participant ${virtualUserId || 'N/A'} for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.participant',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_PARTICIPANT, {
        organizationId,
        studyId,
        virtualUserId
      })}`, {
      method: 'get',
      headers: await prepareHeaders()
    });

    return await res.json() as Participant
  }, {
    retry: false,
    enabled: virtualUserId,
  });


export const useUpdateParticipant: (handlers?: MutationHandlers) => MutationResultPair<ParticipantListItem, Error, { organizationId: string, studyId: string, virtualUserId: string, participant_id?: string, status?: string, status_reason?: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, participant_id, status, status_reason }) => {
    console.log(`[kernel.api.organization.study.participant.update] Updating participant with virtualUserId ${virtualUserId}`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.participant.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_STUDY_PARTICIPANT,
        {
          organizationId,
          studyId,
          virtualUserId
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ participant_id, status, status_reason })
      },
    );

    return await res.json() as ParticipantListItem;
  }, {
    onSuccess: async (result, { organizationId, studyId, virtualUserId, status }) => {
      await queryCache.invalidateQueries(['participants', organizationId, studyId])
      await queryCache.invalidateQueries(['participant', organizationId, studyId, virtualUserId])
      if (status) {
        // changing status may have sent automated message
        await queryCache.invalidateQueries(['messages', organizationId, studyId, virtualUserId])
        await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      }
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export type Invite = {
  invite_id: string;
  participant_id: string;
};

export const generateInvite: (handlers?: MutationHandlers) => MutationResultPair<Invite, Error, {
  organizationId: string;
  studyId: string;
  participant_id: string;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId, participant_id }) => {
      console.log(
        `[portal.api.organization.study.invite] Generating invite for participant ${participant_id} for study ${studyId} and organization ${organizationId}`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.study.invite',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_STUDY_INVITE,
          {
            organizationId,
            studyId,
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({ participant_id })
        },
      );

      return await res.json() as Invite
    }, {
      onSuccess: async (result, { organizationId, studyId }) => {
        await queryCache.invalidateQueries(['participants', organizationId, studyId])
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );

type Reinvite = Pick<Invite, 'invite_id'>

export const useGenerateReinvite: (handlers?: MutationHandlers) => MutationResultPair<Reinvite, Error, {
  organizationId: string;
  studyId: string;
  virtualUserId: string;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId, virtualUserId }) => {
      console.log(
        `[portal.api.organization.study.reinvite] Generating reinvite for participant with virtual_user ${virtualUserId} for study ${studyId} and organization ${organizationId}`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.study.reinvite',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_STUDY_REINVITE,
          {
            organizationId,
            studyId,
            virtualUserId,
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
        },
      );

      return (await res.json()) as Reinvite;
    }, {
      onSuccess: () => {
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );


export const useParticipantsExport: (handlers?: MutationHandlers) => MutationResultPair<void, Error, {
  organizationId: string;
  studyId: string;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId }) => {
      console.log(
        `[portal.api.organization.study.participants.export] Participant export for study ${studyId} and organization ${organizationId}`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.study.participants.export',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_STUDY_PARTICIPANTS_EXPORT,
          {
            organizationId,
            studyId,
          },
        )}`,
        {
          method: 'get',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
        },
      );

      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      downloadFileUrl(url);
    }, {
      onSuccess: () => {
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );

export type ParticipantsImport = {
  not_found: number
  stale: number
  not_updated: number
  error: number
  updated: number
}

export const useParticipantsImport: (handlers?: MutationHandlers) => MutationResultPair<ParticipantsImport, Error, {
  organizationId: string;
  studyId: string;
  file: File;
}, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId, file }) => {
      console.log(
        `[portal.api.organization.study.participants.import] Participant import for study ${studyId} and organization ${organizationId}`,
      );

      const res = await fetchAndRaise(
        'portal.api.organization.study.participants.import',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.ORGANIZATION_STUDY_PARTICIPANTS_IMPORT,
          {
            organizationId,
            studyId,
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': file.type
          }),
          body: await file.text(),
        },
      );

      return await res.json() as ParticipantsImport;
    }, {
      onSuccess: async (result, { organizationId, studyId }) => {
        await queryCache.invalidateQueries(['participants', organizationId, studyId])
        await queryCache.invalidateQueries(['participant', organizationId, studyId])
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    },
    );

/** sessions */

export type SessionMeta = {
  name?: string,
  description?: string,
  experiment?: string,
  number?: string,
  invalid?: string,
}

/**
 * editable meta keys
 */
export const EDITABLE_META_KEYS = {
  name: 'Session Name',
  description: 'Description',
  experiment: 'Task',
  number: 'Session Number',
}

export type Session = {
  id: string,
  created_date: number
  last_uploaded_date: number,
  finalized_at: number
  auto_finalized: boolean
  finalized: boolean,   // if it's still uploading or not
  data_streams: string[],
  data_streams_finalized: string[],
  transformed: boolean, // if it's ready for download
  transform_error?: boolean,
  transformed_data_streams: Record<string, number>
  pipelines_supported: Array<{ job_name: string, job_display_name: string }>
  created_by?: string,
  created_by_email: string,
  created_by_clinic?: string,
  meta?: SessionMeta
  // in seconds
  duration?: number

  // expanded on backend
  participant: Participant
};

export type Sessions = {
  data_streams: Record<string, DataStream>,
  sessions: Session[],
}

type SessionResponse = {
  session: Session
  data_streams: Record<string, DataStream>
  _permissions: {
    can_create_participant_results: boolean
    can_view_participant_photo: boolean
  }

  // available for surveys for study admins on details
  survey?: DataStreamSurvey
  survey_answers?: StudySurveyAnswer[] 
}

export const useSession: (organizationId: string, studyId: string, virtualUserId: string, sessionId: string) => QueryResult<SessionResponse, Error> = (organizationId, studyId, virtualUserId, sessionId) =>
  useQuery(['session', organizationId, studyId, virtualUserId, sessionId], async () => {
    console.log(`[portal.api.session] Fetching session ${sessionId} for ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.session',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_PARTICIPANT_SESSION, { organizationId, studyId, virtualUserId, sessionId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as SessionResponse
  }, {
    retry: false,
  });

type SessionUpdate = Pick<SessionMeta, 'name' | 'description' | 'number' | 'experiment' | 'invalid'>

export const useUpdateSession: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, sessionId: string, update: SessionUpdate }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, sessionId, virtualUserId, update }) => {
    console.log(`[portal.api.session.update] Updating session ${sessionId} for ${virtualUserId} in org ${organizationId} in study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.session.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_PARTICIPANT_SESSION, { organizationId, studyId, virtualUserId, sessionId })}`, {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(update)
    });

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, { organizationId, studyId, virtualUserId, sessionId }) => {
      await queryCache.invalidateQueries(['session', organizationId, studyId, virtualUserId, sessionId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/** upload status */

export type UploadStatsResponse = Partial<{
  files_queued: number
  files_uploaded: number
  last_uploaded_at: number
  time_remaining_secs: number
}>

export const useSessionUploadStats: (enabled: boolean, organizationId: string, studyId: string, virtualUserId: string, sessionId: string) => QueryResult<UploadStatsResponse, Error> = (enabled: boolean, organizationId: string, studyId: string, virtualUserId: string, sessionId: string) =>
  useQuery(['session-upload-stats', organizationId, studyId, virtualUserId, sessionId], async () => {
    console.log(
      `[portal.api.organization.session.upload_stats] Checking session upload stats in ${organizationId} ${studyId} ${sessionId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.organization.session.upload_stats',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.SESSION_UPLOAD_STATS,
        {
          organizationId,
          studyId,
          virtualUserId,
          sessionId,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
      },
    );

    return await res.json() as UploadStatsResponse;
  }, {
    refetchInterval: 5000,
    enabled,
  });

/** downloads */

type FileDownloadResponse = {
  // download_group_id
  id: string
}

export const useDownloadData: (url: string, filter?: StudySessionsFilter, onError?: () => void) => QueryResult<FileDownloadResponse, Error> = (url: string, filter?: StudySessionsFilter, onError?: () => void) =>
  useQuery(['data-files', url, filter], async () => {
    console.log(`[portal.api.download] Downloading ${url} ${filter ? JSON.stringify(filter) : ""}...`);

    const res = await fetchAndRaise(
      'portal.api.download',
      url, {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ filter })
    });

    return await res.json() as FileDownloadResponse
  }, {
    retry: false,
    enabled: false,
    onError: () => {
      onError?.()
    },
    cacheTime: 0
  });

type DownloadStatus = {
  is_error?: boolean
  url?: string
  progress?: number
}

const RETRY_DOWNLOAD_ERROR_NAME = "RETRY_DOWNLOAD"

export const useCheckDownloadStatus: (downloadId?: string, onProgress?: (progress?: number) => void, onError?: () => void) => QueryResult<string, Error> = (downloadId?: string, onProgress?: (progress?: number) => void, onError?: () => void) =>
  useQuery(['download-status', downloadId], async () => {
    console.log(
      `[portal.api.download.status] Checking down status of ${downloadId || ""}...`,
    );

    const res = await fetchAndRaise(
      'portal.api.download.status',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.DOWNLOAD_STATUS,
        {
          downloadId,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
      },
    );

    const { url, is_error, progress } = await res.json() as DownloadStatus;

    if (is_error) {
      const errorMessage = `[portal.api.download.status] is_error is true ${JSON.stringify(await res.json())}`
      console.error(errorMessage)
      Sentry.captureException(errorMessage, {
        extra: {
          url: res.url,
          status: res.status,
          method: 'get',
          is_error
        }
      })
      throw new Error(errorMessage)
    }

    if (!url) {
      if (onProgress) {
        onProgress(progress);
      }

      const retryError = new Error('URL not ready! Retrying...')
      retryError.name = RETRY_DOWNLOAD_ERROR_NAME
      throw retryError
    }

    return url
  }, {
    // only if the error is from an empty URL, keep trying
    retry: (count, error) => {
      if (error.name === RETRY_DOWNLOAD_ERROR_NAME) {
        return true
      }

      return false
    },
    retryDelay: 1000,
    enabled: false,
    onError: error => {
      if (error.name !== RETRY_DOWNLOAD_ERROR_NAME) {
        onError?.()
      }
    },
    cacheTime: 0
  });

/**
 * Poll until downloadable URLs are present and then return them
 */
export const PipelineAssetsMimeType = {
  KTENSOR: "application/vnd.kernel.ktensor",
  VIEW: "application/vnd.kernel.view",
  DOWNLOAD: "application/vnd.kernel.download",
  MINDS_EYE: "application/vnd.kernel.minds-eye",
} as const;

type PipelineAssets = {
  mime_type: (typeof PipelineAssetsMimeType)[keyof typeof PipelineAssetsMimeType],
  urls: Record<string, string>,
  sizes?: Record<string, number>,
}

export const usePipelineAssets: (organizationId: string, studyId: string, sessionId: string, pipelineName: string) => QueryResult<PipelineAssets, Error> = (organizationId: string, studyId: string, sessionId: string, pipelineName: string) =>
  useQuery(['pipeline-assets', organizationId, studyId, sessionId, pipelineName], async () => {
    console.log(
      `[portal.api.pipeline.assets.status] Checking pipeline ${pipelineName} assets status in ${organizationId} ${sessionId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.pipeline.assets.status',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.ORGANIZATION_ASSETS_DOWNLOAD,
        {
          organizationId,
          studyId,
          sessionId,
          pipelineName,
        },
      )}`,
      {
        method: 'get',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as PipelineAssets;
  }, {
    enabled: false // manually trigger refetch
  });

/** participant results */

type ParticipantResultResponse = {
  available?: string,
  available_errors?: string,
  current?: string,
}

export const useParticipantResults: (organizationId: string, studyId: string, virtualUserId: string, sessionId: string) => QueryResult<ParticipantResultResponse, Error> = (organizationId, studyId, virtualUserId, sessionId) =>
  useQuery(['participant-results', organizationId, studyId, virtualUserId, sessionId], async () => {
    console.log(`[portal.api.participant-results] Fetching participant results ${sessionId} for ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant-results',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_RESULTS, { organizationId, studyId, virtualUserId, sessionId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as ParticipantResultResponse
  }, {
    retry: false,
  });

export const useUpdateParticipantResult: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, sessionId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, sessionId, virtualUserId }) => {
    console.log(`[portal.api.session.update-participant-result] Updating participant result ${sessionId} for ${virtualUserId} in org ${organizationId} in study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.session.update-participant-result',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_RESULTS_SET, { organizationId, studyId, virtualUserId, sessionId })}`, {
      method: 'put',
      headers: await prepareHeaders(),
    });

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, { organizationId, studyId, virtualUserId, sessionId }) => {
      await queryCache.invalidateQueries(['participant-results', organizationId, studyId, virtualUserId, sessionId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/** oauth */

export const useOAuth: (handlers?: MutationHandlers) => MutationResultPair<{ redirect_to: string }, Error, { token: string }, unknown> = (handlers) =>
  useMutation(async ({ token }) => {
    console.log(
      `[portal.api.oauth] OAuth...`,
    );

    const res = await fetchAndRaise(
      'portal.api.oauth',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${Routes.OAUTH}`, {
      method: 'post',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ token }),
    });

    return await res.json() as { redirect_to: string }
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  },
  );

/** Google login */

type GoogleLoginResult = { success: boolean, email: string, email_code: string }

export const useGoogleLogin: (handlers?: MutationHandlers) => MutationResultPair<GoogleLoginResult, Error, { token: string }, unknown> = (handlers) =>
  useMutation(async ({ token }) => {
    console.log(
      `[portal.api.google.login] Google Login ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.google.login',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${Routes.GOOGLE_LOGIN}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token }),
    });

    return await res.json() as GoogleLoginResult
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  },
);

/** QR code */

/**
 *   Poll with the secret code to POST /auth/display_code/status with { email, status_code } from challengeParam
 *    * When this is successful, use the email_code response to confirm as usual
 *    * When this fails, rotate the secret_code for the next request
 */

export type QrAuthResult = {
  success: false,
  secret_code?: string,
} | {
  success: true,
  email_code: string
};

export const useCheckQrStatus: (email: string, secret_code: string) => QueryResult<QrAuthResult, Error> = (email, secret_code) =>
  useQuery(['qr-auth-status', secret_code], async () => {
    email = email.trim()
    console.log(`[portal.api.qr-auth.status] Checking QR auth status of ${email || ''}...`);

    try {
      const res = await fetchAndRaise(
        'portal.api.qr-auth.status',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.QR_AUTH_STATUS, {})}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            secret_code
          })
        },
      )

      return await res.json() as QrAuthResult;

    } catch (error: unknown) {
        // coerce into non-successful without a secret code
        return {
          success: false,
        }
    }

  }, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });


/* notes */

export const NotesConfig = {
  study: {
    queryUrl: ({ organizationId, studyId }: { organizationId: string, studyId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_NOTES, { organizationId, studyId })}`,
    mutateUrl: ({ organizationId, studyId, noteId }: { organizationId: string, studyId: string, noteId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_NOTE, { organizationId, studyId, noteId })}`,
  },
  participant: {
    queryUrl: ({ organizationId, studyId, virtualUserId }: { organizationId: string, studyId: string, virtualUserId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_NOTES, { organizationId, studyId, virtualUserId })}`,
    mutateUrl: ({ organizationId, studyId, virtualUserId, noteId }: { organizationId: string, studyId: string, virtualUserId: string, noteId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_NOTE, { organizationId, studyId, virtualUserId, noteId })}`,
  },
  session: {
    queryUrl: ({ organizationId, studyId, virtualUserId, sessionId }: { organizationId: string, studyId: string, virtualUserId: string, sessionId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.SESSION_NOTES, { organizationId, studyId, virtualUserId, sessionId })}`,
    mutateUrl: ({ organizationId, studyId, virtualUserId, sessionId, noteId }: { organizationId: string, studyId: string, virtualUserId: string, sessionId: string, noteId: string }): string => `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.SESSION_NOTE, { organizationId, studyId, virtualUserId, sessionId, noteId })}`,
  },
}

type NotesResponse = Array<{
  id: string
  created_at: number,
  note: string,
  created_by_email: string
  admin_only: boolean
  share_with_org?: boolean
  _permissions: {
    can_edit: boolean
    can_remove: boolean
  }
}>

export const useNotes: <T extends keyof typeof NotesConfig>(type: T, args: Parameters<(typeof NotesConfig)[T]["queryUrl"]>[0]) => QueryResult<NotesResponse, Error> = (type, args) =>
  useQuery([`notes-${type}`, JSON.stringify(args)], async () => {
    console.log(`[portal.api.notes] Fetching notes ${type} ${JSON.stringify(args)} ...`);

    const res = await fetchAndRaise(
      'portal.api.notes',
      // TODO: the `as` type below is a hack to get the typing to work
      NotesConfig[type].queryUrl(args as { organizationId: string, studyId: string, virtualUserId: string, sessionId: string }), {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as NotesResponse
  }, {
    retry: false,
  });

export const useAddNote: <T extends keyof typeof NotesConfig>(type: T, handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, Parameters<(typeof NotesConfig)[T]["queryUrl"]>[0] & { note: string, adminOnly: boolean, shareWithOrg?: boolean }, unknown> = (type, handlers) =>
  useMutation(async (args) => {
    console.log(`[portal.api.note.add] Adding note ${type} ${JSON.stringify(args)} ...`);

    const res = await fetchAndRaise(
      'portal.api.note.add',
      // TODO: the `as` type below is a hack to get the typing to work
      NotesConfig[type].queryUrl(args as { organizationId: string, studyId: string, virtualUserId: string, sessionId: string, note: string, adminOnly: boolean, shareWithOrg?: boolean }), {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ note: args.note, admin_only: args.adminOnly, share_with_org: args.shareWithOrg }),
    });

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, args) => {
      // eslint-disable-next-line
      const { note, adminOnly, shareWithOrg, ...argsWithout } = args;
      await queryCache.invalidateQueries([`notes-${type}`, JSON.stringify(argsWithout)])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useUpdateNote: <T extends keyof typeof NotesConfig>(type: T, handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, Parameters<(typeof NotesConfig)[T]["mutateUrl"]>[0] & { noteId: string, note: string }, unknown> = (type, handlers) =>
  useMutation(async (args) => {
    console.log(`[portal.api.note.update] Updating note ${type} ${JSON.stringify(args)} ...`);

    const res = await fetchAndRaise(
      'portal.api.note.update',
      // TODO: the `as` type below is a hack to get the typing to work
      NotesConfig[type].mutateUrl(args as { organizationId: string, studyId: string, virtualUserId: string, sessionId: string, noteId: string, note: string }), {
      method: 'post',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ note: args.note }),
    });

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, args) => {
      // eslint-disable-next-line
      const { noteId, note, ...argsWithout } = args;
      await queryCache.invalidateQueries([`notes-${type}`, JSON.stringify(argsWithout)])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useRemoveNote: <T extends keyof typeof NotesConfig>(type: T, handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, Parameters<(typeof NotesConfig)[T]["mutateUrl"]>[0] & { noteId: string }, unknown> = (type, handlers) =>
  useMutation(async (args) => {
    console.log(`[portal.api.note.remove] Removing note ${type} ${JSON.stringify(args)} ...`);

    const res = await fetchAndRaise(
      'portal.api.note.remove',
      // TODO: the `as` type below is a hack to get the typing to work
      NotesConfig[type].mutateUrl(args as { organizationId: string, studyId: string, virtualUserId: string, sessionId: string, noteId: string }), {
      method: 'delete',
      headers: await prepareHeaders(),
    });

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, args) => {
      // eslint-disable-next-line
      const { noteId, ...argsWithout } = args;
      await queryCache.invalidateQueries([`notes-${type}`, JSON.stringify(argsWithout)])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/** photo */

type ParticipantPhotoResponse = { url?: string, landmarks: NormalizedLandmark[] }

export const useParticipantPhoto: (organizationId: string, studyId: string, virtualUserId: string, sessionId: string) => QueryResult<ParticipantPhotoResponse, Error> = (organizationId, studyId, virtualUserId, sessionId) =>
  useQuery(['participantPhoto', organizationId, studyId, virtualUserId, sessionId], async () => {
    console.log(`[portal.api.session.participantPhoto] Fetching participant photo ${sessionId} for ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.session.participantPhoto',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_PHOTO, { organizationId, studyId, virtualUserId, sessionId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as ParticipantPhotoResponse
  }, {
    retry: false,
  });

/** messages */

export type Message = {
  id: string
  message_type: "chat" | "email" | "sms"
  message_direction: "inbound" | "outbound"
  member_user_email: string
  body: string
  viewed_by_recipient: boolean
  created_at: number
}

export const useMessages: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<{messages: Message[], messages_require_attention: boolean}, Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useQuery(['messages', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.participant.messages] Fetching messages for organization ${organizationId} and study ${studyId} and virtual user ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant.messages',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_MESSAGES, {
        organizationId,
        studyId,
        virtualUserId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as {messages: Message[], messages_require_attention: boolean};
  }, {
    retry: false,
  });

export const useAddBatchMessage: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, status: string, message: Pick<Message, 'message_type' | 'body'> }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, status, message }) => {
    console.log(`[kernel.api.study.messages] Adding message ${JSON.stringify(message)} to all ${status} participants...`);

    const res = await fetchAndRaise(
      'portal.api.study.messages',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.BATCH_MESSAGES,
        {
          organizationId,
          studyId
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ type: message.message_type, body: message.body, status: status })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['messages', organizationId, studyId])
      await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useAddMessage: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, { organizationId: string, studyId: string, virtualUserId: string, message: Pick<Message, 'message_type' | 'body'> }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, message }) => {
    console.log(`[kernel.api.participant.messages.add] Adding message ${JSON.stringify(message)} to participant ${virtualUserId}...`);

    const res = await fetchAndRaise(
      'portal.api.participant.messages.add',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PARTICIPANT_MESSAGES,
        {
          organizationId,
          studyId,
          virtualUserId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ type: message.message_type, body: message.body })
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async (_, { organizationId, studyId, virtualUserId }) => {
      await queryCache.invalidateQueries(['messages', organizationId, studyId, virtualUserId])
      await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      await queryCache.invalidateQueries(['participants', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useMessageViewed: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, { organizationId: string, studyId: string, virtualUserId: string, messageId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, messageId }) => {
    console.log(`[kernel.api.participant.message.viewed] Viewed message ${messageId} on participant ${virtualUserId}...`);

    const res = await fetchAndRaise(
      'portal.api.participant.message.viewed',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PARTICIPANT_MESSAGE_VIEWED,
        {
          organizationId,
          studyId,
          virtualUserId,
          messageId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async (_, { organizationId, studyId, virtualUserId }) => {
      await queryCache.invalidateQueries(['messages', organizationId, studyId, virtualUserId])
      await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

  export const useToggleNeedsAttention: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, needsAttention: boolean }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, needsAttention }) => {
    console.log(`[kernel.api.participant.messages.notification] Toggling message notifications need attention for participant ${virtualUserId}...`);

    const res = await fetchAndRaise(
      'portal.api.participant.messages.notification',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PARTICIPANT_TOGGLE_NEEDS_ATTENTION,
        {
          organizationId,
          studyId,
          virtualUserId
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          toggle: needsAttention
        })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId, virtualUserId }) => {
      await queryCache.invalidateQueries(['messages', organizationId, studyId, virtualUserId])
      await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })  

  export const useMessagesBulkView: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId }) => {
    console.log(`[kernel.api.participant.messages.view] Marking all messages on participant ${virtualUserId} as viewed...`);

    const res = await fetchAndRaise(
      'portal.api.participant.messages.view',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PARTICIPANT_MESSAGES_VIEW,
        {
          organizationId,
          studyId,
          virtualUserId
        },
      )}`,
      {
        method: 'post',
        headers: await prepareHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          virtual_user_id: virtualUserId
        })
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId, virtualUserId }) => {
      await queryCache.invalidateQueries(['messages', organizationId, studyId, virtualUserId])
      await queryCache.invalidateQueries(['study-inbox', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })


/** study message templates */

export type MessageTemplate = {
  id: string
  name: string
  body: string
  send_on_status_change?: keyof typeof ParticipantStatus | ""
}

export const useMessageTemplates: (organizationId: string, studyId: string, enabled?: boolean) => QueryResult<MessageTemplate[], Error> = (organizationId: string, studyId: string, enabled = true) =>
  useQuery(['message-templates', organizationId, studyId], async () => {
    console.log(`[portal.api.study.message.templates] Fetching message templates for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.study.message.templates',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_MESSAGE_TEMPLATES, {
        organizationId,
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as MessageTemplate[];
  }, {
    retry: false,
    enabled,
  });

export const useAddMessageTemplate: (handlers?: MutationHandlers) => MutationResultPair<{ id: string }, Error, { organizationId: string, studyId: string, messageTemplate: Pick<MessageTemplate, 'name' | 'body' | 'send_on_status_change'> }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, messageTemplate }) => {
    console.log(`[kernel.api.study.message.templates.add] Adding message template ${JSON.stringify(messageTemplate)} to study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.study.message_template.add',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.STUDY_MESSAGE_TEMPLATES,
        {
          organizationId,
          studyId,
        },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(messageTemplate)
      },
    );

    return await res.json() as { id: string };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['message-templates', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: (e) => {
      handlers && handlers.onError && handlers.onError(e);
    }
  })

export const useUpdateMessageTemplate: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, messageTemplateId: string, messageTemplate: Pick<MessageTemplate, 'name' | 'body' | 'send_on_status_change'> }, unknown> = (handlers) =>
    useMutation(async ({ organizationId, studyId, messageTemplateId, messageTemplate }) => {
      console.log(`[kernel.api.message.template.update] Updating message template ${messageTemplateId} on study ${studyId}...`);
  
      const res = await fetchAndRaise(
        'portal.api.study.message_template.update',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
          Routes.STUDY_MESSAGE_TEMPLATE,
          {
            organizationId,
            studyId,
            messageTemplateId,
          },
        )}`,
        {
          method: 'put',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(messageTemplate),
        },
      );
  
      return await res.json() as { success: boolean };
    }, {
      onSuccess: async (_, { organizationId, studyId }) => {
        await queryCache.invalidateQueries(['message-templates', organizationId, studyId])
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    })

export const useDeleteMessageTemplate: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, messageTemplateId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, messageTemplateId }) => {
    console.log(`[kernel.api.message.template.delete] Deleting message template ${messageTemplateId} on study ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.study.message_template.delete',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.STUDY_MESSAGE_TEMPLATE,
        {
          organizationId,
          studyId,
          messageTemplateId,
        },
      )}`,
      {
        method: 'delete',
        headers: await prepareHeaders(),
      },
    );

    return await res.json() as { success: boolean };
  }, {
    onSuccess: async (_, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['message-templates', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/** reports */

export type StudySessionReportsPoint = {
  count: number
  session_duration: number
}

export type StudySessionReportsChart = {
  weeks: string[],
  items: string[],
  data: Record<string, Record<string, StudySessionReportsPoint>> // map of week => item_id => data point
}

export type StudySessionReports = {
  chart_experiment: StudySessionReportsChart
  chart_user: StudySessionReportsChart
}

export const useStudySessionReports: (organizationId: string, studyId: string) => QueryResult<StudySessionReports, Error> = (organizationId: string, studyId: string) =>
  useQuery(['session-reports', organizationId, studyId], async () => {
    console.log(`[portal.api.study.session.reports] Fetching session reports for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.study.session.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_SESSION_REPORTS, {
        organizationId,
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as StudySessionReports;
  }, {
    retry: false,
  });

/* docusign */

type DocuSign = { exists: false } | {
  exists: true,
  email: string,
}

export const useDocuSign: (organizationId: string, studyId: string) => QueryResult<DocuSign, Error> = (organizationId, studyId) =>
  useQuery(['docusign', organizationId, studyId], async () => {
    console.log(`[portal.api.docusign] Fetching docusign ${organizationId} ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN, { organizationId, studyId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as DocuSign
  }, {
    retry: false,
  });

type DocuSignConsent = {
  redirect_uri: string
}

export const useDocuSignConsent: (handlers?: MutationHandlers) => MutationResultPair<DocuSignConsent, Error, { organizationId: string, studyId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId }) => {
    console.log(`[portal.api.docusign.consent] Docusign consent in org ${organizationId} in study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.consent',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_CONSENT, { organizationId, studyId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as DocuSignConsent
  }, {
    onSuccess: (result) => {
      location.href = result.redirect_uri;
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

type DocuSignTemplates = {
  templates: Array<{ name: string, template_id: string }>,
  template_ids: Record<string, string>, // document => template_id
}

export const useDocuSignTemplates: (organizationId: string, studyId: string) => QueryResult<DocuSignTemplates, Error> = (organizationId, studyId) =>
  useQuery(['docusign-templates', organizationId, studyId], async () => {
    console.log(`[portal.api.docusign.templates] Fetching docusign templates ${organizationId} ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.templates',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_TEMPLATES, { organizationId, studyId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as DocuSignTemplates
  }, {
    retry: false,
  });


export const useDocuSignDocumentTemplate: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, document: string, templateId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, document, templateId }) => {
    console.log(`[portal.api.docusign.template] Docusign template ${templateId} in org ${organizationId} in study ${studyId} and document ${document} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.template',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_DOCUMENT_TEMPLATE, { organizationId, studyId, document })}`, {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ template_id: templateId }),
    })

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, { organizationId, studyId }) => {
      await queryCache.invalidateQueries(['docusign-templates', organizationId, studyId])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

type DocuSignStatus = {
  enabled: boolean
  logs: Array<{
    added_by_user_email: string
    event: string
    event_at: number
  }>
  completed_url?: string
}

export const useDocuSignStatus: (organizationId: string, studyId: string, virtualUserId: string, document: string) => QueryResult<DocuSignStatus, Error> = (organizationId, studyId, virtualUserId, document) =>
  useQuery(['docusign-status', organizationId, studyId, virtualUserId, document], async () => {
    console.log(`[portal.api.docusign.status] Fetching docusign ${organizationId} ${studyId} ${virtualUserId} ${document} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.status',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_DOCUMENT_STATUS, { organizationId, studyId, virtualUserId, document })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as DocuSignStatus
  }, {
    retry: false,
  });


export const useSyncDocuSignStatus: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, document: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, document, virtualUserId }) => {
    console.log(`[portal.api.docusign.status.sync] Docusign sync status ${virtualUserId} in org ${organizationId} in study ${studyId} and document ${document} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.status.sync',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_DOCUMENT_STATUS, { organizationId, studyId, virtualUserId, document })}`, {
      method: 'put',
      headers: await prepareHeaders(),
    })

    return await res.json() as { success: boolean }
  }, {
    onSuccess: async (result, { organizationId, studyId, virtualUserId, document }) => {
      await queryCache.invalidateQueries(['docusign-status', organizationId, studyId, virtualUserId, document])
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDocuSignSend: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, document: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, document, virtualUserId }) => {
    console.log(`[portal.api.docusign.send] Docusign send ${virtualUserId} in org ${organizationId} in study ${studyId} and document ${document} ...`);

    const res = await fetchAndRaise(
      'portal.api.docusign.send',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.DOCUSIGN_DOCUMENT_SEND, { organizationId, studyId, virtualUserId, document })}`, {
      method: 'put',
      headers: await prepareHeaders(),
    })

    return await res.json() as { success: boolean }
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

type ParticipantSurveyReports = {
  participants: string[],
  survey_names: string[],
  data: Record<string, Record<string, number>> // map of participant_id => survey_name => count
}

export const useParticipantSurveyReports: (organizationId: string, studyId: string) => QueryResult<ParticipantSurveyReports, Error> = (organizationId: string, studyId: string) =>
  useQuery(['participant-survey-reports', organizationId, studyId], async () => {
    console.log(`[portal.api.study.participant.survey.reports] Fetching participant survey reports for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.study.participant.survey.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_SURVEY_REPORTS, {
        organizationId,
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as ParticipantSurveyReports;
  }, {
    retry: false,
  });

type ParticipantFunnelReports = {
  all_statuses: {
    statuses: string[]
    weeks: string[]
    data: Record<string, Record<string, number>> // map of week => status => count
  }
  screening: {
    withdrew: number
    ineligible: number
    potentially_eligible: number
  }
  ineligibility: Record<string, number> // map of reason => count
  withdrawal: Record<string, number> // map of reason => count
  enrollment: {
    enrolled: number
    eligible: number
  }
  daily_chart: {
    data: Record<string, Record<string, number>> // map of day => status => count
    statuses: string[]
    day: string[]
  }
}

export const useParticipantFunnelReports: (organizationId: string, studyId: string) => QueryResult<ParticipantFunnelReports, Error> = (organizationId: string, studyId: string) =>
  useQuery(['participant-funnel-reports', organizationId, studyId], async () => {
    console.log(`[portal.api.study.participant.funnel.reports] Fetching participant funnel reports for organization ${organizationId} and study ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.study.participant.funnel.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_FUNNEL_REPORTS, {
        organizationId,
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as ParticipantFunnelReports;
  }, {
    retry: false,
  });

/** scheduling */

export type CalendarType = "session" | "icf"

type CalendarList = Record<string, string> // member id => calendar name

type CalendarParticipantEvents = Array<{
  calendar_type: string
  title: string
  member_user_email: string
  start: number
  end: number
}>
export const useCalendarList: (organizationId: string, studyId: string) => QueryResult<CalendarList, Error> = (organizationId, studyId) =>
  useQuery(['calendar-list', organizationId, studyId], async () => {
    console.log(`[portal.api.calendar.list] Fetching calendar list ${organizationId} ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.calendar.list',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDARS, { organizationId, studyId })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as CalendarList
  }, {
    retry: false,
  });

type CalendarEventsMemberBusy = Record<string, { start: number, end: number }>

export const useCalendarEventsMemberBusy: (organizationId: string, studyId: string, memberUserId: string, start: number, end: number) => QueryResult<CalendarEventsMemberBusy, Error> = (organizationId, studyId, memberUserId, start, end) =>
  useQuery(['calendar-events-member-busy', organizationId, studyId, memberUserId, start, end], async () => {
    console.log(`[portal.api.calendar.events] Fetching calendar events member busy ${organizationId} ${studyId} ${memberUserId} ${start} ${end} ...`);

    const res = await fetchAndRaise(
      'portal.api.calendar.events.member.busy',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDAR_EVENTS_MEMBER_BUSY, { organizationId, studyId, memberUserId })}`, {
      method: 'post',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ start, end }),
    });

    return await res.json() as CalendarEventsMemberBusy
  }, {
    enabled: memberUserId,
    retry: false,
  });

export type ResponseStatus = "needsAction" | "declined" | "tentative" | "accepted" // https://developers.google.com/calendar/api/v3/reference/events#attendees.responseStatus

type CalendarEvents = Record<string, {
  title: string
  description: string
  start: number
  end: number
  member?: string
  member_id?: string
  member_status?: ResponseStatus
  participant?: string
  participant_status: ResponseStatus
  google_calendar_event_id?: string
}>

export const useCalendarEvents: (organizationId: string, studyId: string, calendarType: CalendarType, start: number, end: number) => QueryResult<CalendarEvents, Error> = (organizationId, studyId, calendarType, start, end) =>
  useQuery(['calendar-events', organizationId, studyId, calendarType, start, end], async () => {
    console.log(`[portal.api.calendar.events] Fetching calendar events ${organizationId} ${studyId} ${calendarType} ${start} ${end} ...`);

    const res = await fetchAndRaise(
      'portal.api.calendar.events',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDAR_EVENTS, { organizationId, studyId, calendarType })}`, {
      method: 'post',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ start, end }),
    });

    return await res.json() as CalendarEvents
  }, {
    retry: false,
  });

export const useCalendarCreateEvent: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, calendarType: string, memberUserId: string, title: string, description: string, start: number, end: number, batchIntervalMinutes?: number, location?: string, resource?: string, virtualUserId?: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, calendarType, memberUserId, title, description, start, end, batchIntervalMinutes, location, resource, virtualUserId }) => {
    console.log(
      `[portal.api.calendar.event.create] Calendar create event ${organizationId} ${studyId} ${calendarType} ${memberUserId} ${start} ${end} ${batchIntervalMinutes || 0} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.calendar.event.create',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDAR_CREATE_EVENT, { organizationId, studyId, calendarType, memberUserId })}`, {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ title, description, start, end, location, resource, virtual_user_id: virtualUserId, batch_interval_minutes: batchIntervalMinutes }),
    });

    return await res.json() as { success: boolean };
  }, {
    onSuccess: () => {
      // No await here -- avoiding delay between button press (false loading state) and modal close. Alert displays response.
      // Usage in: Create/Delete Event Modal in StudyDetails 
      void queryCache.invalidateQueries('calendar-events')
      void queryCache.invalidateQueries('calendar-events-member-busy')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  },
  );

export const useCalendarDeleteEvent: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, eventId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, eventId }) => {
    console.log(
      `[portal.api.calendar.event.delete] Calendar delete event ${organizationId} ${studyId} ${eventId} ...`,
    );

    const res = await fetchAndRaise(
      'portal.api.calendar.event.delete',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDAR_DELETE_EVENT, { organizationId, studyId, eventId })}`, {
      method: 'DELETE',
      headers: await prepareHeaders(),
    });

    return await res.json() as { success: boolean };
  }, {
    onSuccess: () => {
      // No await here -- avoiding delay between button press (false loading state) and modal close. Alert displays response.
      // Usage in: Create/Delete Event Modal in StudyDetails 
      void queryCache.invalidateQueries('calendar-events')
      void queryCache.invalidateQueries('calendar-events-member-busy')
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  },
  );

export const useCalendarParticipantEvents: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<CalendarParticipantEvents, Error> = (organizationId, studyId, virtualUserId) =>
  useQuery(['calendar-events', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.calendar.events] Fetching calendar events ${organizationId} ${studyId} ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.calendar.events',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CALENDAR_PARTICIPANT_EVENTS, { organizationId, studyId, virtualUserId })}`, {
      method: 'get',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
    });

    return await res.json() as CalendarParticipantEvents
  }, {
    retry: false,
  });

/* Payments */
export type PaymentHistory = {
  id: string
  amount: string
  can_cancel: boolean
  created_at: number
  created_by_email: string
  created_by_user_id: string
  note: string
  payment_type: string
  payment_status: string
}

export type PaymentDetails = {
  amount: string
  email_message?: string
  email_subject?: string
  note: string
  recipient_type: string
}

export type TransactionTypes = 'EMAIL' | 'PHONE' | "MANUAL"

export const usePaymentHistory: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<PaymentHistory[], Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useQuery(['payment-history', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.participant.payment.history] Fetching payment history for organization ${organizationId} and study ${studyId} and virtual user ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant.payment.history',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PAYMENT_HISTORY, {
        organizationId,
        studyId,
        virtualUserId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as PaymentHistory[];
  }, {
    retry: false,
  });

/** Ratings */

export type ParticipantRatingLabel = 'participant_moves' | 'participant_completes' | 'participant_on_time'

export type ParticipantRating = {
  created_at?: number
  score: number
  note?: string
  label: ParticipantRatingLabel
}

export type AverageRatingData = Record<ParticipantRatingLabel, number>

export type RatingHistory = ParticipantRating[]

export type RatingForm = {
  Completes: number,
  CooperationNotes?: string
  Moves: number
  EaseNotes?: string
  OnTime: number
  ReliabilityNotes?: string
}

export const useRatingHistory: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<RatingHistory[], Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useQuery(['rating-history', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.participant.ratings.history] Fetching ratings history for organization ${organizationId} and study ${studyId} and virtual user ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant.ratings.history',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_HISTORICAL_RATING, {
        organizationId,
        studyId,
        virtualUserId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as RatingHistory[];
  }, {
    retry: false,
  })

export const useAverageRatingHistory: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<AverageRatingData, Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useQuery(['average-rating-history', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.participant.ratings.average-history] Fetching ratings history for organization ${organizationId} and study ${studyId} and virtual user ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant.ratings.average-history',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_HISTORICAL_AVG_RATING, {
        organizationId,
        studyId,
        virtualUserId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as AverageRatingData;
  }, {
    retry: false,
  })

export const useParticipantRate: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, rate: RatingHistory }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, rate }) => {
    console.log(`[portal.api.participant.ratings.rate] Sending rating of virtualUser ${virtualUserId}...`)
    const res = await fetchAndRaise(
      'portal.api.participant.rate',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.PARTICIPANT_RATE, { organizationId, studyId, virtualUserId })}`, {
      method: 'put',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(rate)
    })

    return await res.json() as { success: boolean }
  }, {
    onSuccess: () => {
      void queryCache.invalidateQueries('rating-history');
      void queryCache.invalidateQueries('average-rating-history');
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/** payments */

export const useSendPayment: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, payment: PaymentDetails, virtualUserId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, payment, virtualUserId }) => {
    console.log(`[portal.api.participant.payment.pay] Sending payment to virtualUser ${virtualUserId}...`);

    const res = await fetchAndRaise(
      'portal.api.participant.payment.pay',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PAY, {
        organizationId,
        studyId,
        virtualUserId
      },
      )}`,
      {
        method: 'put',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(payment)
      }
    )
    return await res.json() as { success: boolean }
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('payment-history')
      handlers && handlers.onSuccess && handlers.onSuccess()
    },
    onError: () => handlers && handlers.onError && handlers.onError()
  }
  )

export const useCancelPayment: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, paymentHistoryId: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, paymentHistoryId }) => {
    console.log(`[portal.api.participant.payment.cancel_payment] Cancelling payment with payment ID: ${paymentHistoryId} for virtualUser ${virtualUserId}...`);

    const res = await fetchAndRaise(
      'portal.api.participant.payment.cancel',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.PAYMENT_CANCEL, {
        organizationId,
        studyId,
        virtualUserId,
        paymentHistoryId,
      }
      )}`,
      {
        method: 'delete',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
      }
    )
    return await res.json() as { success: boolean }
  }, {
    onSuccess: async () => {
      await queryCache.invalidateQueries('payment-history');
      handlers && handlers.onSuccess && handlers.onSuccess()
    },
    onError: () => handlers && handlers.onError && handlers.onError()
  }
  )

export type StudyInboxMessage = {
  body: string;
  created_at: number;
  deleted_at?: number;
  updated_at?: number;
  id: string;
  member_user_email: string;
  member_user_id: string;
  message_direction: string;
  message_type: string;
  participant_id: string;
  viewed_by_recipient: boolean;
  virtual_user_id: string;
}

export type StudyInbox = StudyInboxMessage[]

export const useStudyInbox: (organizationId: string, studyId: string) => QueryResult<StudyInbox, Error> = (organizationId, studyId) =>
  useQuery(['study-inbox', organizationId, studyId], async () => {
    console.log(`[portal.api.organization.study.inbox] Fetching study inbox ${organizationId} ${studyId} ...`);

    const res = await fetchAndRaise(
      'portal.api.organization.study.inbox',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_INBOX, { organizationId, studyId })}`, {
      method: 'get',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
    });

    return await res.json() as StudyInbox
  }, {
    retry: false,
  });

/** firmware/kortex update */

export type UpdateResponse = {
  firmware_url?: string
  kortex_url?: string
  offline_ui_url?: string
}

export const useUpdate: (handlers?: MutationHandlers) => MutationResultPair<UpdateResponse, Error,  { dataStreamId: string, systemType: string, architecture: string, kortexVersion: string, fwVersion: string, offlineUIVersion: string, }, unknown> = (handlers) =>
  useMutation(async ({ dataStreamId, systemType, architecture, kortexVersion, fwVersion, offlineUIVersion }) => {
    console.log(`[portal.api.organization.update] ${dataStreamId} ${systemType} ${architecture} ${kortexVersion} ${fwVersion} ${offlineUIVersion} ...`);
    
    const res = await fetchAndRaise(
      'portal.api.organization.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.UPDATE,
        { dataStreamId },
      )}`,
        {
          method: 'post',
          headers: await prepareHeaders({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            system_type: systemType,
            architecture,
            kortex_version: kortexVersion,
            fw_version: fwVersion,
            offline_ui_version: offlineUIVersion,
          })
        },
      );

    return await res.json() as UpdateResponse;
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })


/** Study Reports */

export const useStudyReports: (organizationId: string, studyId: string, virtualUserId: string) => QueryResult<{ reports: Record<string, string> }, Error> = (organizationId: string, studyId: string, virtualUserId: string) =>
  useQuery(['study-reports', organizationId, studyId, virtualUserId], async () => {
    console.log(`[portal.api.participant.study.reports] Fetching study reports for organization ${organizationId} and study ${studyId} and virtual user ${virtualUserId} ...`);

    const res = await fetchAndRaise(
      'portal.api.participant.study.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_REPORTS, {
        organizationId,
        studyId,
        virtualUserId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as { reports: Record<string, string> };
  }, {
    retry: false,
  })

export const useCreateStudyReport: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, document: string, file: File }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, document, file }) => {
    console.log(`[portal.api.participant.study.reports] Create study report ${virtualUserId} ${document}...`)
    
    const res = await fetchAndRaise(
      'portal.api.participant.study.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_REPORT, { organizationId, studyId, virtualUserId, document })}`, {
      method: 'put',
      headers: await prepareHeaders(),
    })

    await uploadToS3(res, file)

    return { success: true }
  }, {
    onSuccess: (_ret, { organizationId, studyId, virtualUserId }) => {
      void queryCache.invalidateQueries(['study-reports', organizationId, studyId, virtualUserId]);
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export const useDeleteStudyReport: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { organizationId: string, studyId: string, virtualUserId: string, document: string }, unknown> = (handlers) =>
  useMutation(async ({ organizationId, studyId, virtualUserId, document }) => {
    console.log(`[portal.api.participant.study.reports] Deleting study report ${virtualUserId} ${document}...`)
    const res = await fetchAndRaise(
      'portal.api.participant.study.reports',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.STUDY_REPORT, { organizationId, studyId, virtualUserId, document })}`, {
      method: 'delete',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({})
    })

    return await res.json() as { success: boolean }
  }, {
    onSuccess: (_ret, { organizationId, studyId, virtualUserId }) => {
      void queryCache.invalidateQueries(['study-reports', organizationId, studyId, virtualUserId]);
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

/* Flow UI */

export type FlowUIMeta = {
  study_id: string
  kortex_token: string
  kortex_token_task: string
  upload_token: string
  tasks_url?: string
  // software_version: string // NOTE: not used, but exists on type
  data_streams: (DataStream & { ws_url: string, ws_token: string, ws_sub_token: string })[]
  experiment_names: Record<string, string>
  participants: {
    id: string
    participant_id: string
    active: boolean
  }[]
  configuration?: {
    participant_photo_required?: boolean,
  }
  daq_configuration?: {
    required?: boolean,
  }
  syncbox_configuration?: {
    required?: boolean,
    commands?: object[],
  }
  flow_configuration?: {
    expected_module_count?: number,
  }
}

export const useFlowUI: (organizationId: string, studyId: string) => QueryResult<FlowUIMeta, Error> = (organizationId: string, studyId: string) =>
  useQuery(['study-flow-ui', organizationId, studyId], async () => {
    console.log(`[portal.api.study.flow_ui] Fetching flow ui ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.study.flow_ui',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ORGANIZATION_STUDY_FLOW_UI, {
        organizationId,
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as FlowUIMeta;
  }, {
    retry: false,
  })

/* Clinic support */

type ClinicInfo = { name: string }

export const useClinic: (studyId: string) => QueryResult<ClinicInfo, Error> = (studyId: string) =>
  useQuery(['clinic', studyId], async () => {
    console.log(`[portal.api.clinic] Fetching clinic ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.clinic',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CLINIC, {
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders(),
    });

    return await res.json() as ClinicInfo;
  }, {
    retry: false,
  })

  export const useClinicAuth: (handlers?: MutationHandlers) => MutationResultPair<{ token: string }, Error, { studyId: string, password: string }, unknown> = (handlers) =>
  useMutation(async ({ studyId, password }) => {
    console.log(`[kernel.api.clinic.auth] Auth to ${studyId} ...`);
  
    const res = await fetchAndRaise(
      'portal.api.clinic.auth',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.CLINIC_AUTH,
        {
          studyId,
        },
      )}`,
      {
        method: 'POST',
        headers: await prepareHeaders({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          password
        })
      },
    );

    return await res.json() as { token: string };
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

  type ClinicRegisterInfo = { enrollment_survey?: DataStreamSurvey, protocols: Pick<Protocol, 'id' | 'name'>[] }

  export const useClinicRegisterInfo: (token: string, studyId: string) => QueryResult<ClinicRegisterInfo, Error> = (token: string, studyId: string) =>
  useQuery(['clinic-register', studyId], async () => {
    console.log(`[portal.api.clinic.register] Fetching clinic register ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.clinic.register',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CLINIC_REGISTER, {
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders({
        'x-kernel-clinic': token,
      }),
    });

    return await res.json() as ClinicRegisterInfo;
  }, {
    retry: false,
  })

  export const useClinicRegister: (handlers?: MutationHandlers) => MutationResultPair<{ participant_id: string }, Error, { token: string, studyId: string, survey: SurveyStepForm, protocolId?: string }, unknown> = (handlers) =>
  useMutation(async ({ token, studyId, survey, protocolId }) => {
    console.log(`[kernel.api.clinic.register] Register to ${studyId} ...`);
  
    const res = await fetchAndRaise(
      'portal.api.clinic.register',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.CLINIC_REGISTER,
        {
          studyId,
        },
      )}`,
      {
        method: 'POST',
        headers: await prepareHeaders({
          'Content-Type': 'application/json',
          'x-kernel-clinic': token,
        }),
        body: JSON.stringify({
          enrollment_survey_answers: survey,
          protocol_id: protocolId,
        })
      },
    );

    return await res.json() as { participant_id: string };
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

type ClinicVisitInfo = { participants: { id: string, participant_id: string }[] }

export const useClinicVisitInfo: (token: string, studyId: string) => QueryResult<ClinicVisitInfo, Error> = (token: string, studyId: string) =>
  useQuery(['clinic-visit', studyId], async () => {
    console.log(`[portal.api.clinic.visit] Fetching clinic visit ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.clinic.visit',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CLINIC_VISIT, {
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders({
        'x-kernel-clinic': token,
      }),
    });

    return await res.json() as ClinicVisitInfo;
  }, {
    retry: false,
  })

export type ClinicVisitResponse = {
  virtual_user_id: string
  participant_id: string
  enrollment_survey: DataStreamSurvey
  enrollment_survey_answers: StudySurveyAnswer[]
  protocol?: string
  checklists: { id: string, name: string, complete: boolean, completed_at?: number, missed: boolean }[]
}

export const useClinicVisit: (handlers?: MutationHandlers) => MutationResultPair<ClinicVisitResponse, Error, { token: string, studyId: string, participantId: string }, unknown> = (handlers) =>
  useMutation(async ({ token, studyId, participantId }) => {
    console.log(`[kernel.api.clinic.visit] Visit to ${studyId} ${participantId} ...`);
  
    const res = await fetchAndRaise(
      'portal.api.clinic.visit',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.CLINIC_VISIT,
        {
          studyId,
        },
      )}`,
      {
        method: 'POST',
        headers: await prepareHeaders({
          'Content-Type': 'application/json',
          'x-kernel-clinic': token,
        }),
        body: JSON.stringify({
          participant_id: participantId
        })
      },
    );

    return await res.json() as ClinicVisitResponse;
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

  export type ClinicChecklistLogData = {
    step_id: string
    display_name: string
    checked?: boolean
    completed_at?: number
    notes?: string
    step_type?: ChecklistStepType
    meta?: Record<string, string>
    warnings: string[],
    metrics?: {
      motion_score?: { rating?: number },
      percent_channels_retained?: { extra_values?: { percent_channels_retained_per_plate?: { Frontoparietal?: number, L_Temporal?: number, R_Temporal?: number } } },
    }
  }
  
  export type ClinicChecklistLogValues = Pick<ClinicChecklistLogData, 'step_id' | 'checked' | 'notes'>
  
  export type ClinicChecklistLog = {
    id: string
    name: string
    complete: boolean
    missed: boolean
    data: ClinicChecklistLogData[]
    virtual_user_id: string,
    protocol: string,
    participant_id: string
  }

export const useClinicChecklistLog: (token: string, studyId: string, checklistLogId: string) => QueryResult<ClinicChecklistLog, Error> = (token: string, studyId: string, checklistLogId: string) =>
useQuery(['clinic-checklist-log', studyId, checklistLogId], async () => {
  console.log(
    `[portal.api.clinic.checklist] Fetching checklist log for clinic  in study ${studyId} for log ${checklistLogId} ...`,
  );

  const res = await fetchAndRaise(
    'portal.api.clinic.checklist',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_CHECKLIST,
      {
        studyId,
        checklistLogId,
      },
    )}`,
    {
      method: 'get',
      headers: await prepareHeaders({
        'x-kernel-clinic': token,
      }),
    },
  );

  return await res.json() as ClinicChecklistLog;
}, {
  retry: false,
});

export const useUpdateClinicChecklistLog: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { token: string, studyId: string, checklistLogId: string, values: ClinicChecklistLogValues[] }, unknown> = (handlers) =>
useMutation(async ({ token, studyId, checklistLogId, values }) => {
  console.log(`[kernel.api.clinic.checklist.log.update] Updating log for checklist ${checklistLogId} to study ${studyId} ...`);

  const res = await fetchAndRaise(
    'portal.api.clinic.checklist.log.update',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_CHECKLIST,
      {
        studyId,
        checklistLogId
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
        'x-kernel-clinic': token,
      }),
      body: JSON.stringify({
        values
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('clinic-checklist-log')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useClinicSetChecklistLogMissed: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { token: string, studyId: string, checklistLogId: string, missed: boolean }, unknown> = (handlers) =>
useMutation(async ({ token, studyId, checklistLogId, missed }) => {
  console.log(`[kernel.api.clinic.checklist.log.missed] Updating log missed ${missed ? "true" : "false"} for checklist ${checklistLogId} to study ${studyId} ...`);

  const res = await fetchAndRaise(
    'portal.api.clinic.checklist.log.missed',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_CHECKLIST_MISSED,
      {
        studyId,
        checklistLogId
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
        'x-kernel-clinic': token,
      }),
      body: JSON.stringify({
        missed
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: async () => {
    await queryCache.invalidateQueries('clinic-checklist-log')
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useClinicSurvey: (token: string, studyId: string, checklistLogId: string, stepId: string) => QueryResult<DataStream, Error> = (token: string, studyId: string, checklistLogId: string, stepId: string) =>
useQuery(['clinic-survey', studyId, checklistLogId, stepId], async () => {
  console.log(
    `[portal.api.clinic.survey] Fetching survey log for clinic in study ${studyId} for log ${checklistLogId} and step ${stepId} ...`,
  );

  const res = await fetchAndRaise(
    'portal.api.clinic.survey',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_SURVEY,
      {
        studyId,
        checklistLogId,
        stepId,
      },
    )}`,
    {
      method: 'get',
      headers: await prepareHeaders({
        'x-kernel-clinic': token,
      }),
    },
  );

  return await res.json() as DataStream;
}, {
  retry: false,
});

type ClinicSurveySubmitResult = {
  success: boolean
  next_step_id?: string
  next_step_survey_name?: string
  next_clinician_administered?: boolean
}

export const useClinicSurveySubmit: (handlers?: MutationHandlers) => MutationResultPair<ClinicSurveySubmitResult, Error, { token: string, studyId: string, checklistLogId: string, stepId: string, answers: SurveyStepForm }, unknown> = (handlers) =>
useMutation(async ({ token, studyId, checklistLogId, stepId, answers }) => {
  console.log(`[kernel.api.clinic.survey.submit] Submitting survey for checklist ${checklistLogId} and step ${stepId} to study ${studyId} ...`);

  const res = await fetchAndRaise(
    'portal.api.clinic.survey.submit',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_SURVEY,
      {
        studyId,
        checklistLogId,
        stepId,
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
        'x-kernel-clinic': token,
      }),
      body: JSON.stringify({
        answers
      })
    },
  );

  return await res.json() as ClinicSurveySubmitResult;
}, {
  onSuccess: () => {
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useClinicFlowUI: (token: string, studyId: string) => QueryResult<FlowUIMeta, Error> = (token: string, studyId: string) =>
  useQuery(['clinic-flow-ui', studyId], async () => {
    console.log(`[portal.api.clinic.flow_ui] Fetching clinic flow ui ${studyId}...`);

    const res = await fetchAndRaise(
      'portal.api.clinic.flow_ui',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CLINIC_FLOW_UI, {
        studyId,
      })}`, {
      method: 'get',
      headers: await prepareHeaders({
        'x-kernel-clinic': token,
      }),
    });

    return await res.json() as FlowUIMeta;
  }, {
    retry: false,
  })

  export const useClinicNextTask: (token: string, studyId: string, checklistLogId: string, stepId: string, handlers?: MutationHandlers) => MutationResultPair<UpdateResponse, Error, Record<string, string>, unknown> = (token, studyId, checklistLogId, stepId, handlers) =>
    useMutation(async () => {
      console.log(`[portal.api.clinic.next_task] Fetching clinic next task ${studyId} ${checklistLogId} ${stepId}...`);
  
      const res = await fetchAndRaise(
        'portal.api.clinic.next_task',
        `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.CLINIC_NEXT_TASK, {
          studyId,
          checklistLogId,
          stepId,
        })}`, {
        method: 'get',
        headers: await prepareHeaders({
          'x-kernel-clinic': token,
        }),
      });
  
      return await res.json() as Record<string, string>;
    }, {
      onSuccess: () => {
        handlers && handlers.onSuccess && handlers.onSuccess();
      },
      onError: () => {
        handlers && handlers.onError && handlers.onError();
      }
    })

  export const useClinicUpdate: (studyId: string, token: string, handlers?: MutationHandlers) => MutationResultPair<UpdateResponse, Error, { dataStreamId: string, systemType: string, architecture: string, kortexVersion: string, fwVersion: string, offlineUIVersion: string }, unknown> = (studyId, token, handlers) =>
  useMutation(async ({ dataStreamId, systemType, architecture, kortexVersion, fwVersion, offlineUIVersion }) => {
    console.log(`[portal.api.clinic.update] ${studyId} ${dataStreamId} ${systemType} ${architecture} ${kortexVersion} ${fwVersion} ${offlineUIVersion} ...`);
    
    const res = await fetchAndRaise(
      'portal.api.clinic.update',
      `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
        Routes.CLINIC_UPDATE,
        { studyId, dataStreamId },
      )}`,
        {
          method: 'post',
          headers: await prepareHeaders({
            'Content-Type': 'application/json',
            'x-kernel-clinic': token,
          }),
          body: JSON.stringify({
            system_type: systemType,
            architecture,
            kortex_version: kortexVersion,
            fw_version: fwVersion,
            offline_ui_version: offlineUIVersion,
          })
        },
      );

    return await res.json() as UpdateResponse;
  }, {
    onSuccess: () => {
      handlers && handlers.onSuccess && handlers.onSuccess();
    },
    onError: () => {
      handlers && handlers.onError && handlers.onError();
    }
  })

export type KortexVersionResponse = { kortex_version: string, system_type: string, system_arch: string, hostname: string, offline_ui_version: string }

export const useKortexVersion: () => QueryResult<KortexVersionResponse, Error> = () =>
useQuery(['kortex-version'], async () => {
  console.log(`[portal.api.kortex.version] Fetching kortex version ...`);

  const res = await fetchAndRaise(
    'portal.api.kortex.version',
    "http://127.0.0.1:13254/version", {
    method: 'get',
  });

  return await res.json() as KortexVersionResponse;
}, {
  retry: false,
})

export const useSurvey: (token?: string) => QueryResult<DataStream, Error> = (token?: string) =>
useQuery(['survey', token], async () => {
  console.log(
    `[portal.api.survey] Fetching survey for token ${token || 'N/A'} ...`,
  );

  const res = await fetchAndRaise(
    'portal.api.survey',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.SURVEY,
      {
        token,
      },
    )}`,
    {
      method: 'get',
      headers: await prepareHeaders({}),
    },
  );

  return await res.json() as DataStream;
}, {
  retry: false,
  enabled: !!token,
});

export const useSurveySubmit: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { token: string, answers: SurveyStepForm }, unknown> = (handlers) =>
useMutation(async ({ token, answers }) => {
  console.log(`[kernel.api.survey.submit] Submitting survey for token ${token} ...`);

  const res = await fetchAndRaise(
    'portal.api.survey.submit',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.SURVEY,
      {
        token,
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        answers
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: () => {
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

type StudyEnrollInfo = { name: string, description?: string, enrollment_survey: DataStreamSurvey }

export const useEnroll: (studyId?: string) => QueryResult<StudyEnrollInfo, Error> = (studyId?: string) =>
useQuery(['enroll', studyId], async () => {
  console.log(`[portal.api.enroll] Fetching enroll ${studyId || 'N/A'}...`);

  const res = await fetchAndRaise(
    'portal.api.enroll',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(Routes.ENROLL, {
      studyId,
    })}`, {
    method: 'get',
    headers: await prepareHeaders({}),
  });

  return await res.json() as StudyEnrollInfo;
}, {
  retry: false,
  enabled: !!studyId,
})

export const useEnrollSubmit: (handlers?: MutationHandlers) => MutationResultPair<{ success: boolean }, Error, { studyId: string, survey: SurveyStepForm }, unknown> = (handlers) =>
useMutation(async ({ studyId, survey }) => {
  console.log(`[kernel.api.enroll] Enroll to ${studyId} ...`);

  const res = await fetchAndRaise(
    'portal.api.enroll',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.ENROLL,
      {
        studyId,
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        enrollment_survey_answers: survey,
      })
    },
  );

  return await res.json() as { success: boolean };
}, {
  onSuccess: () => {
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useSurveyToken: (handlers?: MutationHandlers) => MutationResultPair<{ token: string }, Error, { organizationId: string, studyId: string, virtualUserId: string, dataStreamId: string }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, virtualUserId, dataStreamId }) => {
  console.log(`[kernel.api.survey.token] Survey token ${organizationId} ${studyId} ${virtualUserId} ${dataStreamId} ...`);

  const res = await fetchAndRaise(
    'portal.api.survey.token',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.SURVEY_TOKEN,
      {
        organizationId,
        studyId,
        virtualUserId,
        dataStreamId,
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({}),
    },
  );

  return await res.json() as { token: string };
}, {
  onSuccess: () => {
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})

export const useClinicSurveyToken: (handlers?: MutationHandlers) => MutationResultPair<{ token: string }, Error, { organizationId: string, studyId: string, checklistLogId: string, stepId: string }, unknown> = (handlers) =>
useMutation(async ({ organizationId, studyId, checklistLogId, stepId }) => {
  console.log(`[kernel.api.clinic.survey.token] Clinic survey token ${organizationId} ${studyId} ${checklistLogId} ${stepId} ...`);

  const res = await fetchAndRaise(
    'portal.api.clinic.survey.token',
    `${process.env.REACT_APP_PORTAL_API_CONFIG_URL}${generatePath(
      Routes.CLINIC_SURVEY_TOKEN,
      {
        organizationId,
        studyId,
        checklistLogId,
        stepId,
      },
    )}`,
    {
      method: 'POST',
      headers: await prepareHeaders({}),
    },
  );

  return await res.json() as { token: string };
}, {
  onSuccess: () => {
    handlers && handlers.onSuccess && handlers.onSuccess();
  },
  onError: () => {
    handlers && handlers.onError && handlers.onError();
  }
})