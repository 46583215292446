import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DataStreamSurvey, MessageTemplate, ParticipantStatus, useAddMessageTemplate, useCloseStudy, useDeleteMessageTemplate, useMessageTemplates, useUpdateStudy, useDocuSign, useDocuSignConsent, useDocuSignDocumentTemplate, useDocuSignTemplates, useCalendarList, useCalendarEvents, useCalendarCreateEvent, useCalendarDeleteEvent, ResponseStatus, CalendarType, useCalendarEventsMemberBusy, useParticipants, DataStreamSurveyStepQuestion, SurveyType, useUpdateMessageTemplate } from '../../api'
import { Typography, Space, List, Popconfirm, Card, Form, Input, Alert, Switch, Tooltip, Spin, Empty, Tabs, Table, Tag, Select, Divider, Modal, Descriptions, Button, App, QRCode } from 'antd'
import { PageHeader } from "@ant-design/pro-components"
import { generatePath, useHistory, useParams } from "react-router-dom";
import { EditOutlined, StopOutlined, CheckCircleOutlined, InfoOutlined, DeleteOutlined } from '@ant-design/icons'
import { useSelectedStudyContext } from '../../contexts/SelectedStudy'
import { Loader, LoadingIndicator } from '../Loader'
import { useForm } from 'antd/lib/form/Form';
import { SurveyPreview } from '../Survey/Preview';
import { ColumnsType } from 'antd/lib/table';
import Notes from '../Notes';
import StudyReports from '../StudyReports';
import MarkdownPreview from '@uiw/react-markdown-preview';
import CoverPhoto from '../CoverPhoto';

import { Calendar, momentLocalizer, Event as CalendarEvent, SlotInfo } from 'react-big-calendar'
import Linkify from 'react-linkify';

import moment from 'moment'
import CustomMDEditor from '../CustomMarkdownEditor';
import MDEditor from '@uiw/react-md-editor';
import { SurveyBuilder } from '../Survey/Builder';
import { downloadJson, redirectPortalToResearch } from 'src/utilities';
import { color } from '../../theme';

const localizer = momentLocalizer(moment)

const { Text, Title, Paragraph, Link } = Typography
const { Option } = Select

type SurveyBuildingProps = {
  setSurveyBuilding: Dispatch<SetStateAction<boolean>>
  setSurveyQuestions: Dispatch<SetStateAction<DataStreamSurveyStepQuestion[]>>
  setSurveyType: Dispatch<SetStateAction<SurveyType>>
  activeTab: string
}

const EnrollmentSettings: React.FC<SurveyBuildingProps> = ({ activeTab, setSurveyBuilding, setSurveyQuestions, setSurveyType }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { study } = useSelectedStudyContext()
  const [form] = useForm()

  const [participantPrefix, setParticipantPrefix] = useState<string>()
  const [participantSize, setParticipantSize] = useState<number>()
  const [isEnrolling, setIsEnrolling] = useState<boolean>()
  const [enrollmentSurvey, setEnrollmentSurvey] = useState<DataStreamSurvey | undefined>()
  const [description, setDescription] = useState<string>("")
  const { message: messageApi } = App.useApp();

  useEffect(() => {
    if (study && activeTab === 'enrollmentSettings') {
      setParticipantPrefix(study.participant_prefix)
      setParticipantSize(study.participant_size)
      setIsEnrolling(study.is_enrolling)
      setEnrollmentSurvey(study.enrollment_survey)
      setDescription(study.description)
      setSurveyType(SurveyType.Enrollment)
      study.enrollment_survey?.steps.length ? setSurveyQuestions(study.enrollment_survey.steps[0].questions) : setSurveyQuestions([])
    }
  }, [study, activeTab])

  const [updateStudy, { isLoading: updateStudyLoading }] = useUpdateStudy({
    onError: () => {
      void messageApi.error('Failed to set enrollment settings')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated enrollment settings')
    }
  })

  const enrollmentUrl = isEnrolling && enrollmentSurvey && redirectPortalToResearch(`${window.location.origin}/enroll/${studyId}`)

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Card title="Enrollment Settings">
        <Form form={form} onFinish={() => updateStudy({ organizationId, studyId, participantPrefix, participantSize, description, isEnrolling, enrollmentSurvey })}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Title level={5} style={{ margin: 0 }}>Participant ID Autogeneration</Title>
              <Alert message={`Participant IDs will appear as: ${[1, 2, 3].map(n => `${participantPrefix || ''}${"0".repeat((participantSize || 1) - 1)}${n}`).join(', ')} ...`} type="info" showIcon />
              <Space direction="horizontal">
                <Form.Item label="Participant ID Prefix" required={true}>
                  <Input onChange={({ target }) => setParticipantPrefix(target.value)} value={participantPrefix} />
                </Form.Item>
                <Form.Item label="Participant ID Size" required={true}>
                  <Input type="number" min={1} max={7} onChange={({ target }) => setParticipantSize(parseInt(target.value, 10))} value={participantSize} />
                </Form.Item>
              </Space>
            </Space>
            <Form.Item label={
              <Space direction="horizontal">
                <Tooltip title="Allows participants to auto-enroll in the study. Any enrolled participant begins in candidate status.">
                  <Button shape="circle" size="small" icon={<InfoOutlined />} />
                </Tooltip>
                Enrolling participants?
              </Space>
            }>
              <Switch checked={isEnrolling} onChange={value => setIsEnrolling(value)} />
            </Form.Item>
            <Form.Item label="Enrollment Survey">
              <Space direction="horizontal">
                <Button type="primary" onClick={() => setSurveyBuilding(true)}>Build Enrollment Survey</Button>
                {enrollmentSurvey?.steps && <>
                  <SurveyPreview name="Study Enrollment Survey" survey={enrollmentSurvey} />
                  <Button onClick={() => downloadJson(enrollmentSurvey, `${study?.name || 'Study'} Enrollment Survey`)}>
                    Download
                  </Button>
                </>}
              </Space>
            </Form.Item>
            <Form.Item label="Description">
              <MDEditor height={400} value={description} onChange={(description) => setDescription(description ?? '')} />
            </Form.Item>
            {enrollmentUrl && (
              <Form.Item label="Enrollment Link">
                <Paragraph style={{ marginTop: 5, width: 200 }}>
                  <Link href={enrollmentUrl} copyable target='_blank' ellipsis>{enrollmentUrl}</Link>
                </Paragraph>
                <QRCode value={enrollmentUrl} size={200} />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={updateStudyLoading}>Submit</Button>
            </Form.Item>
          </Space>
        </Form>
      </Card>
      {study?._permissions.can_edit_study_image && (
        <Card title="Cover Photo">
          <CoverPhoto study={study} image={study.image} organizationId={organizationId} studyId={studyId} />
        </Card>
      )}
    </Space>
  )
}

const ConsentSettings: React.FC<SurveyBuildingProps> = ({ activeTab, setSurveyBuilding, setSurveyQuestions, setSurveyType }) => {
  const { study } = useSelectedStudyContext();

  const [consentSurvey, setConsentSurvey] = useState<DataStreamSurvey | undefined>()

  useEffect(() => {
    if (study && activeTab === 'consentSettings') {
      setSurveyType(SurveyType.Consent)
      setConsentSurvey(study.consent_survey)
      study.consent_survey?.steps.length ? setSurveyQuestions(study.consent_survey.steps[0].questions) : setSurveyQuestions([])
    }
  }, [study, activeTab])


  return (
    <Card title="Consent Settings">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space direction="horizontal">
          <Button type="primary" onClick={() => setSurveyBuilding(true)}>Build Consent Survey</Button>
          {consentSurvey?.steps && <SurveyPreview name="Study Consent Survey" survey={consentSurvey} />}
        </Space>
      </Space>
    </Card>
  )
}

const DocuSignTemplates: React.FC<{ document: string }> = ({ document }) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { study } = useSelectedStudyContext()
  const { data: docuSignTemplates, isLoading: docuSignTemplatesLoading } = useDocuSignTemplates(organizationId, studyId)
  const { message: messageApi } = App.useApp();
  const [updateDocuSignDocumentTemplate, { isLoading: updateDocuSignDocumentTemplateLoading }] = useDocuSignDocumentTemplate({
    onError: () => {
      void messageApi.error(`Error updating ${document} template`)
    },
    onSuccess: () => {
      void messageApi.success(`Successfully updated ${document} template`)
    }
  })

  if (!study?._permissions?.can_enroll) {
    return <></>
  }

  return (
    <Space direction="vertical">
      {docuSignTemplatesLoading ? (
        <Spin />
      ) : docuSignTemplates ? (
        <>
          <Title level={4}>{document} template</Title>
          <Select style={{ width: 400 }} disabled={updateDocuSignDocumentTemplateLoading} value={docuSignTemplates.template_ids[document] || ""} onChange={(templateId) => updateDocuSignDocumentTemplate({ organizationId, studyId, document, templateId })}>
            <Select.Option value="">None</Select.Option>
            {docuSignTemplates.templates.map(template => (
              <Select.Option key={template.template_id} value={template.template_id}>{template.name}</Select.Option>
            ))}
          </Select>
        </>
      ) : <Empty description="No Templates" />}
    </Space>
  )
}

const DocuSignSettings: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { data: docuSign, isLoading: docuSignLoading } = useDocuSign(organizationId, studyId)
  const { message: messageApi } = App.useApp();

  const [docuSignConsent, { isLoading: docuSignConsentLoading }] = useDocuSignConsent({
    onError: () => {
      void messageApi.error('Failed to link to DocuSign')
    },
  })

  return (
    <Card title="DocuSign Settings">
      <Space direction="vertical">
        {docuSignLoading ? (
          <Spin />
        ) : !docuSign?.exists ? (
          <Alert type="warning" showIcon message="Not Linked" />
        ) : (
          <Alert type="info" showIcon message={<>Linked with account: <b>{docuSign.email}</b></>} />
        )}
        <Button type="primary" loading={docuSignConsentLoading} disabled={docuSignConsentLoading} onClick={() => docuSignConsent({ organizationId, studyId })}>Link DocuSign</Button>
        <Divider />
        {docuSign?.exists && (
          <Space direction="vertical" size="large">
            <Alert type="info" showIcon message="For templates to be available for selection:" description={
              <ul style={{ paddingLeft: 20 }}>
                <li>The folder in DocuSign must be the same exact name as the study</li>
                <li>
                  The template must have 2 recipients
                  <ul>
                    <li>
                      The first recipient&apos;s role name must be <code>participant</code>.<br />
                      Leave the name and email fields empty.
                    </li>
                    <li>
                      The second recipient&apos;s role name must be <code>researcher</code>.<br />
                      Fill in the name and email fields with the researcher who will be signing.
                    </li>
                  </ul>
                </li>
              </ul>
            } />
            <DocuSignTemplates document="ICF" />
            <DocuSignTemplates document="W9" />
            <DocuSignTemplates document="NDA" />
          </Space>
        )}
      </Space>
    </Card>
  )
}

type KernelEventType = "event" | "event_with_attending_participant" | "busy"

type KernelEvent = CalendarEvent & {
  eventId: string,
  eventType: KernelEventType,
  title: string,
  description: string,
  member?: string,
  memberId?: string,
  memberStatus?: ResponseStatus,
  participant?: string,
  participantStatus?: ResponseStatus,
}

const ParticipantStatusMap: Record<ResponseStatus | "open", { text: string, color: string }> = {
  "open": {
    text: "Open",
    color: "purple",
  },
  "needsAction": {
    text: "Invited",
    color: "blue",
  },
  "declined": {
    text: "Declined",
    color: "orange",
  },
  "tentative": {
    text: "Tentative",
    color: "yellow",
  },
  "accepted": {
    text: "Accepted",
    color: "green",
  },
}

const getEventColor = (event: KernelEvent) => {
  if (event.eventType === "busy") return "red"
  return ParticipantStatusMap[event.participantStatus || "open"].color
}

const getEventStatus = (status?: ResponseStatus) => {
  const participantStatus = ParticipantStatusMap[status || "open"]
  return <Tag color={participantStatus.color}>{participantStatus.text}</Tag>
}

const CALENDAR_PRIMARY = "primary"

export const startEnd = (start?: Date, end?: Date): React.ReactNode[] => {
  if (!start || !end) {
    return []
  }
  const mStart = moment(start)
  const mEnd = moment(end)
  if (mStart.clone().startOf("day").unix() === mEnd.clone().startOf("day").unix()) {
    return [
      <Descriptions.Item key="Date" label="Date">{mStart.clone().startOf("day").format("MMMM Do YYYY")}</Descriptions.Item>,
      <Descriptions.Item key="Time" label="Time">{mStart.format("h:mma")} to {mEnd.format("h:mma")}</Descriptions.Item>,
    ]
  }

  return [
    <Descriptions.Item key="Start" label="Start">{mStart.format()}</Descriptions.Item>,
    <Descriptions.Item key="End" label="End">{mEnd.format()}</Descriptions.Item>,
  ]
}

const Scheduling: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const { study, isFetchingSelectedStudy: studyLoading } = useSelectedStudyContext();

  const [calendarType, setCalendarType] = useState<CalendarType>("icf")
  const [selectedCalendar, setSelectedCalendar] = useState<string>(CALENDAR_PRIMARY)
  const [range, setRange] = useState<{ start: number, end: number }>({
    start: moment().startOf("week").unix(),
    end: moment().endOf("week").unix(),
  })

  // for new events
  const [selectedSlot, setSelectedSlot] = useState<SlotInfo>()
  const [selectedParticipant, setSelectedParticipant] = useState<string>("")
  const [selectedBatchIntervalMinutes, setSelectedBatchIntervalMinutes] = useState<number>()

  // existing event
  const [selectedEvent, setSelectedEvent] = useState<KernelEvent>()

  const { data: calendarList, isLoading: calendarListLoading } = useCalendarList(organizationId, studyId)
  const { data: calendarEventsMemberBusy, isLoading: calendarEventsLoadingMemberBusy } = useCalendarEventsMemberBusy(organizationId, studyId, selectedCalendar !== CALENDAR_PRIMARY ? selectedCalendar : "", range.start, range.end)
  const { data: calendarEvents, isLoading: calendarEventsLoading } = useCalendarEvents(organizationId, studyId, calendarType, range.start, range.end)
  const { data: participants, isLoading: participantsLoading } = useParticipants(organizationId, studyId)
  const { message: messageApi } = App.useApp();

  const [calendarCreateEvent, { isLoading: calendarCreateEventLoading }] = useCalendarCreateEvent({
    onError: () => {
      void messageApi.error('Failed to create event')
    },
    onSuccess: () => {
      void messageApi.success('Successfully created event')
    }
  })

  const [calendarDeleteEvent, { isLoading: calendarDeleteEventLoading }] = useCalendarDeleteEvent({
    onError: () => {
      void messageApi.error('Failed to delete event')
    },
    onSuccess: () => {
      void messageApi.success('Successfully deleted event')
    }
  })

  if (studyLoading || calendarListLoading || participantsLoading || !study) {
    return <Spin />
  }

  return (
    <Space direction="vertical">
      <Card title="Calendars" extra={
        <Space direction="horizontal">
          ICF
          <Tooltip title="Session scheduling not yet enabled">
            <Switch disabled={true} checked={calendarType === "session"} onChange={() => setCalendarType(calendarType === "icf" ? "session" : "icf")} />
          </Tooltip>
          Sessions
        </Space>
      }>
        <Space direction="vertical">
          <Space direction="horizontal">
            <Select style={{ width: 400 }} value={selectedCalendar} onChange={(value) => setSelectedCalendar(value)}>
              <Select.Option value={CALENDAR_PRIMARY}>Study Events</Select.Option>
              {Object.entries(calendarList || {}).map(([calendarId, calendarName]) => (
                <Select.Option key={calendarId} value={calendarId}>{calendarName}</Select.Option>
              ))}
            </Select>
            <Tooltip placement='bottom' title={
              <>
                <Paragraph>The Study Events calendar is read-only and lets you see all the events across the study. Click on an event to access its details.</Paragraph>
                <Paragraph>You can also see details or create events on member calendars. To create an event, drag in an empty area.</Paragraph>
                <Paragraph>Member calendars show up if their account email ends in @kernel.com</Paragraph>
              </>
            }>
              <Button shape="circle" size="small" icon={<InfoOutlined />} />
            </Tooltip>
          </Space>
          <Space direction="horizontal">
            <Text>Event Colors:</Text>
            <Alert type="error" showIcon message="Busy" />
            {Object.values(ParticipantStatusMap).map(({ color, text }) => (
              <Tag key={text} color={color}>{text}</Tag>
            ))}
          </Space>
        </Space>
      </Card>
      <Modal
        title={`Create Event: ${calendarType === "icf" ? `Review ${study.name} Study Consent` : `${study.name} Study Visit`}`}
        width={600}
        closable={false}
        open={!!selectedSlot}
        okButtonProps={{
          disabled: calendarCreateEventLoading,
          loading: calendarCreateEventLoading,
        }}
        cancelButtonProps={{
          disabled: calendarCreateEventLoading,
        }}
        onOk={async () => {
          // TODO: hide dialog right away so they can't press ok again?
          await calendarCreateEvent({
            organizationId,
            studyId,
            calendarType,
            memberUserId: selectedCalendar,
            title: calendarType === "icf" ? `Kernel: Review ${study.name} Study Consent` : `Kernel: ${study.name} Study Visit`,
            description: '',
            start: Math.floor((selectedSlot?.start as Date).getTime() / 1000),
            end: Math.floor((selectedSlot?.end as Date).getTime() / 1000),
            virtualUserId: selectedParticipant || undefined,
            batchIntervalMinutes: selectedBatchIntervalMinutes,
          })

          setSelectedSlot(undefined)
          setSelectedParticipant("")
          setSelectedBatchIntervalMinutes(undefined)
        }}
        onCancel={() => setSelectedSlot(undefined)}
      >
        <Space direction="vertical">
          <Descriptions bordered layout="horizontal" column={1}>
            <Descriptions.Item label="Participant">
              <Space direction='horizontal'>
                <Select value={selectedParticipant} onChange={value => {
                  setSelectedParticipant(value)
                  if (!value) setSelectedBatchIntervalMinutes(undefined)
                }}>
                  <Option value="">-- Open Slot --</Option>
                  {participants?.participants?.filter(participant => !participant.pending).map(participant => (
                    <Select.Option key={participant.id} value={participant.id}>{participant.participant_id}</Select.Option>
                  ))}
                </Select>
                <Tooltip title="Select Open Slot to let a participant book themselves, or pick a participant to book on their behalf.">
                  <Button shape="circle" size="small" icon={<InfoOutlined />} />
                </Tooltip>
              </Space>
            </Descriptions.Item>
            {selectedSlot && startEnd(selectedSlot.start as Date, selectedSlot.end as Date)}
            {!selectedParticipant && (
              <Descriptions.Item label="Batch">
                <Space direction='vertical'>
                  <Select style={{ width: 150 }} value={selectedBatchIntervalMinutes ? selectedBatchIntervalMinutes.toString() : ""} onChange={value => setSelectedBatchIntervalMinutes(value ? parseInt(value) : undefined)}>
                    <Option value="">-- Single Event --</Option>
                    <Option value="30">30 minute slots</Option>
                    <Option value="60">1 hour slots</Option>
                  </Select>
                  {selectedSlot && selectedBatchIntervalMinutes && (
                    <Alert type='info' showIcon message={`Creating ${Math.floor(((selectedSlot.end as Date).getTime() - (selectedSlot.start as Date).getTime()) / 60000 / selectedBatchIntervalMinutes)} events`} />
                  )}
                </Space>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Space>
      </Modal>
      <Modal
        title="Study Event"
        width={600}
        closable={false}
        open={!!selectedEvent}
        onOk={() => setSelectedEvent(undefined)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {selectedEvent && (
          <Space direction='vertical'>
            <Descriptions bordered layout="horizontal" column={1}>
              <Descriptions.Item label="Title">{selectedEvent.title}</Descriptions.Item>
              <Descriptions.Item label="Description"><Linkify>{selectedEvent.description}</Linkify></Descriptions.Item>
              {startEnd(selectedEvent.start as Date, selectedEvent.end as Date)}
              <Descriptions.Item label="Researcher">{getEventStatus(selectedEvent.memberStatus)} {selectedEvent.member}</Descriptions.Item>
              <Descriptions.Item label="Participant">{getEventStatus(selectedEvent.participantStatus)} {selectedEvent.participant || '-- none --'}</Descriptions.Item>
            </Descriptions>

            {study._permissions.can_delete_events && <Button danger disabled={calendarDeleteEventLoading} loading={calendarDeleteEventLoading} onClick={async () => {
              await calendarDeleteEvent({ organizationId, studyId, eventId: selectedEvent.eventId })
              setSelectedEvent(undefined)
            }}>Delete</Button>}
          </Space>
        )}
      </Modal>
      <Calendar<KernelEvent>
        localizer={localizer}
        defaultView="week"
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 19, 0, 0)}
        events={calendarEventsLoading || calendarEventsLoadingMemberBusy ? [] : (() => {
          const ret: KernelEvent[] = []
          const seenEvents: Set<string> = new Set()
          Object.entries(calendarEvents || {}).forEach(([eventId, event]) => {
            if (selectedCalendar !== CALENDAR_PRIMARY && selectedCalendar !== event.member_id) {
              return
            }
            ret.push({
              eventId,
              eventType: "event",
              title: event.title,
              description: event.description,
              start: new Date(event.start * 1000),
              end: new Date(event.end * 1000),
              member: event.member,
              memberId: event.member_id,
              memberStatus: event.member_status,
              participant: event.participant,
              participantStatus: event.participant_status,
            })
            if (event.google_calendar_event_id) seenEvents.add(event.google_calendar_event_id)
          })
          if (selectedCalendar !== CALENDAR_PRIMARY) {
            Object.entries(calendarEventsMemberBusy || {}).forEach(([googleCalendarEventId, event]) => {
              if (seenEvents.has(googleCalendarEventId)) {
                return
              }
              ret.push({
                eventId: googleCalendarEventId,
                eventType: "busy",
                title: "Busy",
                description: "",
                start: new Date(event.start * 1000),
                end: new Date(event.end * 1000),
              })
            })
          }
          return ret
        })()}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onRangeChange={(val) => {
          if (Array.isArray(val)) {
            const times = val.map(d => Math.floor(d.getTime() / 1000));
            setRange({
              start: Math.min(...times) - 60 * 60 * 24,
              end: Math.max(...times) + 60 * 60 * 24,
            })
          } else {
            setRange({
              start: Math.floor((val.start as Date).getTime() / 1000) - 60 * 60 * 24,
              end: Math.floor((val.end as Date).getTime() / 1000) + 60 * 60 * 24,
            })
          }
        }}
        selectable={selectedCalendar !== CALENDAR_PRIMARY}
        onSelectSlot={slot => setSelectedSlot(slot)}
        onSelectEvent={event => {
          if (event.eventType === "busy") {
            return
          }
          setSelectedEvent(event)
        }}
        eventPropGetter={(event) => ({ style: { backgroundColor: getEventColor(event) } })}
      />
    </Space>
  )
}

const MessageTemplates: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { data: messageTemplates, isLoading: messageTemplatesLoading } = useMessageTemplates(organizationId, studyId)
  const [form] = Form.useForm()
  const { message: messageApi } = App.useApp();

  const [newName, setNewName] = useState<string>("")
  const [newBody, setNewBody] = useState<string>("")
  const [newSendOnStatusChange, setNewSendOnStatusChange] = useState<keyof typeof ParticipantStatus | "">("")

  const [editingMessageTemplateId, setEditingMessageTemplateId] = useState<string | undefined>()

  const [addMessageTemplate, {
    isLoading: addMessageTemplateLoading
  }] = useAddMessageTemplate({
    onError: (error) => {
      if (error?.status === 409) {
        void messageApi.error('Message template with status already exists')
        return
      }

      void messageApi.error('Failed to add message template')
    },
    onSuccess: () => {
      void messageApi.success('Successfully added message template')
      setNewName("")
      setNewBody("")
      setNewSendOnStatusChange("")
    }
  })

  const [updateMessageTemplate, { isLoading: updateMessageTemplateIsLoading }] = useUpdateMessageTemplate({
    onError: () => {
      void messageApi.error('Failed to update message template')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated message template')
    }
  })

  const [deleteMessageTemplate, { isLoading: deleteMessageTemplateIsLoading }] = useDeleteMessageTemplate({
    onError: () => {
      void messageApi.error('Failed to delete message template')
    },
    onSuccess: () => {
      void messageApi.success('Successfully deleted message template')
    }
  })

  const columns: ColumnsType<MessageTemplate> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
      render: function Body(body: string) {
        return <MarkdownPreview source={body} />
      }
    },
    {
      title: (
        <Space direction="horizontal">
          <Tooltip title="When a participant changes to this status, the system will automatically send this template to their email.">
            <Button shape="circle" size="small" icon={<InfoOutlined />} />
          </Tooltip>
          Send on Status Change
        </Space>
      ),
      dataIndex: 'send_on_status_change',
      key: 'send_on_status_change',
      render: function SendOnStatusChange(send_on_status_change: keyof typeof ParticipantStatus | "") {
        return send_on_status_change && <Tag>{send_on_status_change}</Tag>
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: function Actions(_, { id, name, body, send_on_status_change }) {
        return (
          <Space direction="horizontal">
            <Tooltip title='Edit Message Template' placement="bottomRight" arrow={{ pointAtCenter: true }}>
              <EditOutlined style={{ color: color.blue }} onClick={() => {
                setNewName(name)
                setNewBody(body)
                setNewSendOnStatusChange(send_on_status_change || "")
                setEditingMessageTemplateId(id)
              }} />
            </Tooltip>
            <Popconfirm
              title={() => <Text>Are you sure you want to remove <strong>{name}</strong>?</Text>}
              onConfirm={() => void deleteMessageTemplate({ organizationId, studyId, messageTemplateId: id })}
              arrow={{ pointAtCenter: true }}
            >
              <Tooltip title='Remove Message Template' placement="bottomRight" arrow={{ pointAtCenter: true }}>
                {deleteMessageTemplateIsLoading ? <Loader size="small" /> : <DeleteOutlined style={{ color: color.red }} />}
              </Tooltip>
            </Popconfirm>
          </Space>
        )
      }
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Title level={3} style={{ marginTop: 0 }}>Message Templates</Title>

      <Table
        pagination={{ simple: true, pageSize: 10, hideOnSinglePage: true }}
        {...(messageTemplatesLoading) && {
          loading: {
            indicator: <LoadingIndicator />
          }
        }}
        dataSource={messageTemplates}
        columns={columns}
        rowKey="id"
      />

      <Card title={`${editingMessageTemplateId ? "Edit" : "Add"} Message Template`}>
        <Form form={form} onFinish={() =>
          editingMessageTemplateId ?
          updateMessageTemplate({ organizationId, studyId, messageTemplateId: editingMessageTemplateId, messageTemplate: { name: newName, body: newBody, send_on_status_change: newSendOnStatusChange } }) :
          addMessageTemplate({ organizationId, studyId, messageTemplate: { name: newName, body: newBody, send_on_status_change: newSendOnStatusChange } })
        }>
          <Form.Item label="Name" required={true}>
            <Input value={newName} onChange={({ target }) => setNewName(target.value)} />
          </Form.Item>
          <Form.Item label="Body" required={true}>
            <CustomMDEditor height={200} value={newBody} onChange={setNewBody} autofillCommands="study" />
          </Form.Item>
          <Form.Item label={
            <Space direction="horizontal">
              <Tooltip title="When a participant changes to this status, the system will automatically send this template to their email.">
                <Button shape="circle" size="small" icon={<InfoOutlined />} />
              </Tooltip>
              Send on Status Change
            </Space>
          }>
            <Select value={newSendOnStatusChange} style={{ width: 200 }} onChange={(value) => setNewSendOnStatusChange(value)}>
              <Option value="">None</Option>
              {Object.entries(ParticipantStatus).map(([status, { text }]) => (
                <Option key={status} value={status}>{text}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" disabled={addMessageTemplateLoading || updateMessageTemplateIsLoading || !newName || !newBody}>Submit</Button>
              {editingMessageTemplateId && <Button onClick={() => {
                setNewName("")
                setNewBody("")
                setNewSendOnStatusChange("")
                setEditingMessageTemplateId(undefined)
              }}>Cancel</Button>}
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Space>
  )
}

const ClinicSettings: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();

  const [updateStudy, { isLoading: updateStudyLoading }] = useUpdateStudy({
    onError: () => {
      void messageApi.error('Failed to set clinic settings')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated clinic settings')
    }
  })

  const clinicUrl = redirectPortalToResearch(`${window.location.origin}/clinic/${studyId}`)

  return (
    <Card title="Clinic Settings">
      {study?.clinic_enabled ? <Alert type='success' showIcon message='Clinic Enabled' /> : <Alert type='error' showIcon message='Clinic Disabled - set password to enable' />}
      <Paragraph style={{ marginTop: 20 }}>
          <Text strong>Link:</Text><br />
          <Link href={clinicUrl} copyable target='_blank'>{clinicUrl}</Link>
      </Paragraph>
      {!updateStudyLoading ? (
        <Paragraph>
          <Text strong>Password:</Text><br />
          <Text
            code
            editable={{
              icon: <EditOutlined style={{ color: color.blue }} />,
              onChange: value => {
                void updateStudy({ clinicPassword: value, organizationId, studyId })
              }
            }}
          >
          </Text>
        </Paragraph>
      ) : <Loader size="small" />}
    </Card>
  )
}

const CloseStudy: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string, virtualUserId: string }>();
  const { study } = useSelectedStudyContext()
  const { message: messageApi } = App.useApp();
  const [closeStudy] = useCloseStudy({
    onError: () => {
      void messageApi.error('Failed to close study')
    },
    onSuccess: () => {
      void messageApi.success('Successfully closed study')
    },
  })

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={3} style={{ marginTop: 0 }}>Close Study</Title>

      <Space direction="vertical">
        <Paragraph>
          <Paragraph>
            <Paragraph>
              ⚠️ This is an <strong>irreversible action</strong>!
            </Paragraph>
            A study will close automatically after some time of inactive data collection. Additionally, you can
            manually close a study using the button below. Before doing so, make sure you are aware of the <em>data
              consequences</em> from closing a study.
          </Paragraph>
        </Paragraph>

        <Paragraph>
          <List>
            <List.Item>
              <Space align="start">
                <StopOutlined style={{ color: color.red }} />
                <strong>The study becomes readonly and no more settings can be changed</strong>
              </Space>
            </List.Item>
            <List.Item>
              <Space align="start">
                <StopOutlined style={{ color: color.red }} />
                <strong>No more participant data can be uploaded or collected</strong>
              </Space>
            </List.Item>
            <List.Item>
              <Space align="start">
                <CheckCircleOutlined style={{ color: color.green }} />
                <strong>Researchers will continue to be able to view, download, and analyze all previously collected participant data</strong>
              </Space>
            </List.Item>
            <List.Item>
              <Space align="start">
                <CheckCircleOutlined style={{ color: color.green }} />
                <strong>Control of data is released to the participant</strong>
              </Space>
            </List.Item>
          </List>
        </Paragraph>

        <Popconfirm
          title={() => <Text>Are you sure you want to <strong>permanently close {study?.name}</strong>?</Text>}
          onConfirm={() => closeStudy({ organizationId, studyId, name: study?.name ?? '' })}
          placement={'right'}
          arrow={{ pointAtCenter: true }}
        >
          <Button type="primary" danger icon={<StopOutlined />} size="large">Close Study</Button>
        </Popconfirm>
      </Space>
    </Space>
  )
}

export const StudyDetail: React.FC = () => {
  const { organizationId, studyId, activeTab } = useParams<{ organizationId: string, studyId: string, activeTab: string }>();
  const { study, isFetchingSelectedStudy: studyLoading } = useSelectedStudyContext();
  const title = study?.name;

  const complete = study?.is_complete;

  const canModifyStudy = study?._permissions.can_modify

  const [surveyBuilding, setSurveyBuilding] = useState<boolean>(false)
  const [surveyType, setSurveyType] = useState<SurveyType>(SurveyType.Enrollment);
  const [surveyQuestions, setSurveyQuestions] = useState<DataStreamSurveyStepQuestion[]>([])
  const { message: messageApi } = App.useApp();

  const [updateStudy] = useUpdateStudy({
    onError: () => {
      void messageApi.error('Failed to set update study name')
    },
    onSuccess: () => {
      void messageApi.success('Successfully updated study name')
    }
  })

  const history = useHistory();
  const handleTabClick = (key: string) => {
    history.push(generatePath('/organizations/:organizationId/studies/:studyId/details/:activeTab', { organizationId: organizationId, studyId: studyId, activeTab: key }))
  }

  return (
    <>
      {surveyBuilding && <SurveyBuilder surveyType={surveyType} setIsVisible={setSurveyBuilding} surveyQuestions={surveyQuestions} />}
      {!surveyBuilding &&
        <PageHeader
          title={
            <Space direction="vertical" size="small">
              {complete && <Alert showIcon type="error" icon={<StopOutlined />} message="Study Closed" />}
              {(title && !studyLoading) ? (
                <Title
                  level={1}
                  style={{ margin: 0 }}
                  {...canModifyStudy && {
                    editable: {
                      icon: <EditOutlined style={{ fontSize: 24, color: color.blue }} />,
                      onChange: value => {
                        if (value && value !== study?.name) {
                          void updateStudy({ name: value, organizationId, studyId })
                        }
                      }
                    }
                  }}
                >
                  {title}
                </Title>
              ) : <Loader size="small" />}
            </Space>
          }
          style={{ paddingTop: 0 }}
        >
          <Space size="large" direction="vertical" style={{ width: '100%' }}>
            {studyLoading ?
              <Spin /> :
              <Tabs defaultActiveKey="enrollmentSettings" activeKey={activeTab} onChange={(activeKey) => handleTabClick(activeKey)} tabPosition="left" style={{ paddingTop: 10 }} size="large"
                items={[
                  ...(study?._permissions.can_enroll ? [{ key: "enrollmentSettings", label: "Enrollment Settings", children: <EnrollmentSettings activeTab={activeTab} setSurveyBuilding={setSurveyBuilding} setSurveyType={setSurveyType} setSurveyQuestions={setSurveyQuestions} /> }] : []),
                  ...(study?._permissions.can_clinic ? [{ key: "clinicSettings", label: "Clinic Settings", children: <ClinicSettings /> }] : []),
                  ...(study?._permissions.can_add_consent_survey ? [{ key: "consentSettings", label: "Consent Settings", children: <ConsentSettings activeTab={activeTab} setSurveyBuilding={setSurveyBuilding} setSurveyType={setSurveyType} setSurveyQuestions={setSurveyQuestions} /> }] : []),
                  ...(study?._permissions.can_enroll ? [{ key: "documents", label: "Documents", children: <DocuSignSettings /> }] : []),
                  ...(study?._permissions.can_schedule ? [{ key: "scheduling", label: "Scheduling", children: <Scheduling /> }] : []),
                  ...(study?._permissions.can_manage_templates ? [{ key: "messageTemplates", label: "Message Template", children: <MessageTemplates /> }] : []),
                  ...(study?._permissions.can_view_study_reports ? [{ key: "reports", label: "Reports", children: <StudyReports /> }] : []),
                  { key: "notes", label: "Notes", children: <Notes type="study" args={{ organizationId, studyId }} /> },
                  ...(study?._permissions.can_close ? [{ key: "closeStudy", label: "Close Study", children: <CloseStudy /> }] : []),
                ]}
              />
            }
          </Space>
        </PageHeader>
      }
    </>
  )
}
